import React from 'react';
import './Selector.css'

const LevelSelector = ({ levels = [], selectedLevel, onSelectLevel }) => {
  return (
    <div className="mb-4">
      <label className="indication-text">Sélectionner un niveau</label>
      <select
        className="selector-container"
        value={selectedLevel}
        onChange={(e) => onSelectLevel(e.target.value)}
      >
        <option value="">Sélectionner un niveau</option>
        {levels.map((level, index) => (
          <option key={index} value={level}>
            {level}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LevelSelector;