// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Chemin vers le fichier contenant les variables */


h1,h2,h3 {color: var(--color-text);
    font-family: 'Paytone One';
}
h1 {
    font-size: 2rem; 
    font-weight: 400; 
    margin-bottom: 1.5rem;
  }

h2 {
    font-size: 1.5rem; 
    font-weight: 300; 
    margin-bottom: 1rem;
  }

h3 {
    font-size: 20px; 
    font-weight: 300; 
    margin-bottom: 16px;
}

.name {
    color: var(--color-text);
    font-family: 'Paytone One';
    font-size: 20px; 
    font-weight: 300; 
}

.big-text {
    color: var(--color-text);
    font-size: 16px;
    font-weight: 600;
}

.text {
    color: var(--color-text);
    font-size: 16px;
}

.subtext {
    color: var(--color-subtext);
    font-size: 12px;
}

.success-text{
    color: var(--color-success);
    font-size: 16px;
    font-weight: 600;
} `, "",{"version":3,"sources":["webpack://./src/components/Title.css"],"names":[],"mappings":"AAAsB,mDAAmD;;;AAGzE,UAAU,wBAAwB;IAC9B,0BAA0B;AAC9B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;EACvB;;AAEF;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;;AAEF;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["@import '../App.css'; /* Chemin vers le fichier contenant les variables */\n\n\nh1,h2,h3 {color: var(--color-text);\n    font-family: 'Paytone One';\n}\nh1 {\n    font-size: 2rem; \n    font-weight: 400; \n    margin-bottom: 1.5rem;\n  }\n\nh2 {\n    font-size: 1.5rem; \n    font-weight: 300; \n    margin-bottom: 1rem;\n  }\n\nh3 {\n    font-size: 20px; \n    font-weight: 300; \n    margin-bottom: 16px;\n}\n\n.name {\n    color: var(--color-text);\n    font-family: 'Paytone One';\n    font-size: 20px; \n    font-weight: 300; \n}\n\n.big-text {\n    color: var(--color-text);\n    font-size: 16px;\n    font-weight: 600;\n}\n\n.text {\n    color: var(--color-text);\n    font-size: 16px;\n}\n\n.subtext {\n    color: var(--color-subtext);\n    font-size: 12px;\n}\n\n.success-text{\n    color: var(--color-success);\n    font-size: 16px;\n    font-weight: 600;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
