import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_URL } from '../config';
import { Button } from './Teacher/Evaluation/components/ui/button';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UserRound, Lock } from 'lucide-react';


const Login = ({ onLogin }) => {
  const [username_or_email, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (process.env.REACT_APP_LOCAL_DEV == 'true') {
      const userType = process.env.REACT_APP_USER_TYPE;
      console.log('Logging in as', userType);
      onLogin(userType);
      localStorage.setItem('userId', '1'); 
      localStorage.setItem('email', 'test@example.com'); 
      localStorage.setItem('userName', 'Test User'); 
      localStorage.setItem('userFirstName', 'First_name');
      localStorage.setItem('userLastName', 'Last_name');
      localStorage.setItem('schoolName', 'Test School'); 
      localStorage.setItem('userType', userType);
      navigate('/profile');
    } else {

      try {
        const response = await axios.post(`${BACKEND_URL}/backapp/login/`, {
          username_or_email,
          password,
        });
        
        console.log('Login response:', response.data); // Debug response
        
        if (response.data && response.data.tokens) {
          // Check if we have the required data
          const { tokens, userType, userId, email, userFirstName, userLastName, schoolName } = response.data;
          
          if (tokens.access && tokens.refresh) {
            onLogin(userType);
            localStorage.setItem('token', tokens.access);
            localStorage.setItem('refreshToken', tokens.refresh);
            localStorage.setItem('userId', userId);
            localStorage.setItem('email', email);
            localStorage.setItem('userFirstName', userFirstName);
            localStorage.setItem('userLastName', userLastName);
            localStorage.setItem('schoolName', schoolName);
            localStorage.setItem('userType', userType);
            navigate('/profile');
          } else {
            toast.error("Erreur lors de la connexion: tokens invalides");
          }
        } else {
          toast.error("Format de réponse invalide");
          console.error('Invalid response format:', response.data);
        }
      } catch (error) {
        toast.error("Email/nom d'utilisateur ou mot de passe incorrect");
        console.error('Login error:', error.response?.data || error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <div className="flex items-center justify-center mx-auto min-h-screen">
      <div className="bg-white p-8 sm:p-8 rounded-lg shadow-md w-full max-w-md mx-auto w-[90vw] sm:w-[400px] md:w-[450px] lg:w-[500px] max-w-full">
        <ToastContainer />
        <div className='flex items-center justify-center'>
          <h1 className="mb-6" style={{ color: "var(--color-primary)" }}>
            edjoy!
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
          <label className="subtext flex gap-1 items-center pb-0.5"><UserRound size={14} />Nom d'utilisateur ou email</label>
            <input
              type="text"
              value={username_or_email}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              placeholder="Entre ton nom d'utilisateur ou ton email..."
            />
          </div>
          <div className="mb-4">
            <label className="subtext flex gap-1 items-center pb-0.5"><Lock size={14} /> Mot de passe</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              placeholder="Entre ton mot de passe..."
            />
          </div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <div className="flex justify-center pt-6">
          <Button type="submit" className="button-primary items-center justify-center">
            Se connecter
          </Button>
          </div>
        </form>
      </div>
    </div>
  );  
};

export default Login;