import React from 'react';
import './progressbar.css';

interface ProgressBarProps {
  label: string;
  percentage: number;
  color?: string;
}

const getProgressBarColor = (score: number): string => {
  if (score > 60) return 'var(--color-success)';
  if (score > 30) return 'var(--color-warning)';
  return 'var(--color-danger)';
};

const ProgressBar: React.FC<ProgressBarProps> = ({ label, percentage, color }) => {
  return (
    <div className="mb-4 w-full">
      <div className="flex justify-between mb-1">
        <span className='subtext'>{label}</span>
      </div>
      <div className="progress-bar-container">
        <div
          className="progress-bar-inside-bar"
          style={{ 
            width: `${percentage}%`, 
            minWidth: "2%", // Largeur minimale pour que la barre soit toujours visible
            backgroundColor: color || getProgressBarColor(percentage),
          }}
        />
        <p className='percentage-text' style={{ color: percentage === 100 ? "white" : "var(--color-subtext" }}>
          {percentage.toFixed(0)}%
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
