import React, { useState } from 'react';
import LevelSelector from './LevelSelector'; // Adjust the import path as necessary
import CreateEvaluationButton from './CreateEvaluationButton'; // Adjust the import path as necessary
import QuizCreation from './QuizCreation'; // Adjust the import path as necessary
import ShareModal from './ShareModal'; // Adjust the import path as necessary
import {Trash2} from 'lucide-react';
import './Evaluation.css'



const Evaluation = () => {
  const [selectedLevel, setSelectedLevel] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEvaluation, setCurrentEvaluation] = useState(null);
  const [isSharing, setIsSharing] = useState(false);

  // warning msg car variable déclarée (selectedEvaluation) et valeur assignée mais jamais utilisée dans le code
  // eslint-disable-next-line no-unused-vars 
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState([]);

  const levels = ['Class 1', 'Class 2', 'Class 3', 'Class 4'];
  const evaluations = {
    'Class 1': [
      { title: 'Evaluation 1', theme: 'Math', chapter: 'Algebra', notion: 'Equations' },
      { title: 'Evaluation 2', theme: 'Science', chapter: 'Physics', notion: 'Motion' },
    ],
    'Class 2': [
      { title: 'Evaluation 3', theme: 'Math', chapter: 'Geometry', notion: 'Shapes' },
    ],
    'Class 3': [
      { title: 'Evaluation 4', theme: 'History', chapter: 'Ancient', notion: 'Egypt' },
    ],
    'Class 4': [
      { title: 'Evaluation 5', theme: 'Geography', chapter: 'Maps', notion: 'World Maps' },
    ],
  };

  const handleCreateEvaluation = () => {
    setIsCreating(true);
  };

  const handleCancelCreate = () => {
    setIsCreating(false);
  };

  const handleSaveEvaluation = (evaluation) => {
    // Save evaluation logic here
    setIsCreating(false);
  };

  const handleEditEvaluation = (evaluation) => {
    setCurrentEvaluation(evaluation);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setCurrentEvaluation(null);
  };

  const handleSaveEdit = (evaluation) => {
    // Save edited evaluation logic here
    setIsEditing(false);
    setCurrentEvaluation(null);
  };

  const handleDeleteEvaluation = (title) => {
    // Delete evaluation logic here
  };

  const handleShareEvaluation = (evaluation) => {
    setSelectedEvaluation(evaluation);
    setIsSharing(true);
  };

  const handleCancelShare = () => {
    setIsSharing(false);
    setSelectedEvaluation(null);
    setSelectedClasses([]);
  };

  const handleValidateShare = () => {
    // Implement the logic to share the evaluation with the selected classes
    console.log('Evaluation shared with classes:', selectedClasses);
    setIsSharing(false);
    setSelectedEvaluation(null);
    setSelectedClasses([]);
  };

  return (
    <div className="p-8 relative">
      <h1>Mes évaluations</h1>
      {!isCreating && !isEditing && (
        <LevelSelector levels={levels} selectedLevel={selectedLevel} onSelectLevel={setSelectedLevel} />
      )}
      {selectedLevel && (
        <>
          {isCreating ? (
            <QuizCreation onCancel={handleCancelCreate} onSave={handleSaveEvaluation} />
          ) : isEditing ? (
            <>
              <QuizCreation
                onCancel={handleCancelEdit}
                onSave={handleSaveEdit}
                initialData={currentEvaluation}
              />
              {/* 
              <div className="flex space-x-4 mt-4 justify-end">
              
                <button
                  onClick={handleCancelEdit}
                  className="button-outline"
                >
                  Annuler
                </button>
                <button
                  onClick={handleSaveEdit}
                  className="button-primary"
                >
                  Sauvegarder
                </button>
                
              </div>
              */}
            </>
          ) : (
            <>
              <CreateEvaluationButton onCreate={handleCreateEvaluation} />
              <div className="eval-en-prep-container">
                <h2>Evaluations en préparation</h2>
                {(evaluations[selectedLevel] || []).map(evaluation => (
                  <div key={evaluation.title} className="mb-6">
                    <div className="big-text">{evaluation.title}</div>
                    <div className="subtext">
                      Thème: {evaluation.theme}, Chapitre: {evaluation.chapter}, Notion: {evaluation.notion}
                    </div>
                    <div className="flex space-x-4 mt-4">

                      <button
                        onClick={() => handleShareEvaluation(evaluation)}
                        className="button-primary"
                      >
                        Partager
                      </button>
                      <button
                        onClick={() => handleEditEvaluation(evaluation)}
                        className="button-outline"
                      >
                        Modifier
                      </button>
                      <button
                        onClick={() => handleDeleteEvaluation(evaluation.title)}
                        className="button-danger"
                      >
                        <Trash2 size={20} />
                      </button>

                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      <ShareModal
        isOpen={isSharing}
        onClose={handleCancelShare}
        onValidate={handleValidateShare}
        classes={['Classe 1', 'Classe 2', 'Classe 3']} // Replace with actual classes for the selected level
        selectedClasses={selectedClasses}
        setSelectedClasses={setSelectedClasses}
      />
    </div>
  );
};

export default Evaluation;