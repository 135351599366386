import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BACKEND_URL } from '../../config';

const SupportPage = () => {
  const [issueType, setIssueType] = useState('');
  const [details, setDetails] = useState('');
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedUserType = localStorage.getItem('userType');
    setUserId(storedUserId);
    setUserType(storedUserType);
  }, []);

  const handleIssueTypeChange = (event) => {
    setIssueType(event.target.value);
  };

  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validation des champs
    if (!issueType || !details) {
      toast.error('Veuillez remplir tous les champs.');
      return;
    }
    try {
      const response = await axios.post(`${BACKEND_URL}/backapp/help/`, {
        issueType: issueType,
        userId: userId,
        details: details,
      });
      console.log('Réponse du serveur:', response.data);
      toast.success('Votre demande de support a été envoyée avec succès !');
    } catch (error) {
      console.error('Erreur lors de l\'envoi des données:', error);
      toast.error('Une erreur est survenue lors de l\'envoi de votre demande de support.');
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-semibold mb-4">Aide et Recommandation</h2>
      <p className="mb-4">Partagez les bugs ou recommandations pour notre application 😊</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Type de problème:</label>
          <select
            value={issueType}
            onChange={handleIssueTypeChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Sélectionnez une option</option>
            <option value="bug">Bug</option>
            <option value="recommendation">Recommandation</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Détails:</label>
          <textarea
            value={details}
            onChange={handleDetailsChange}
            className="w-full p-2 border border-gray-300 rounded"
            rows="5"
            placeholder="Décrivez votre problème ou recommandation ici..."
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded"
        >
          Soumettre
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default SupportPage;