import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EvaluationDetails from './EvaluationDetails';
import TakeQuiz from './TakeQuiz';
import PracticeQuestions from './PracticeQuestions';
import '../Title.css' // Importation du fichier CSS ;
import '../Button.css' // Importation du fichier CSS ;
import './StudentEvaluation.css'
import ProgressBar from '../ProgressBar';
import { BACKEND_URL } from '../../config';

const getProgressBarColor = (score) => {
  if (score > 60) return 'var(--color-success)';
  if (score > 30) return 'var(--color-warning)';
  return 'var(--color-danger)';
};

const Evaluations = () => {
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [isTakingQuiz, setIsTakingQuiz] = useState(false);
  const [isViewingPreviousQuiz, setIsViewingPreviousQuiz] = useState(false);
  const [selectedNotion, setSelectedNotion] = useState(null);
  const [userEvaluationsTaken, setUserEvaluationsTaken] = useState([]);
  const [userEvaluationsPending, setUserEvaluationsPending] = useState([]);
  const [notionsGrades, setNotionsGrades] = useState({});

  const refreshEvalData = async () => {
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/student/trialquizzes/${userId}/`);
      const { trial_quizzes, average_scores_per_notion } = response.data;

      const taken = trial_quizzes.filter(quiz => quiz.attempted);
      const pending = trial_quizzes.filter(quiz => !quiz.attempted);

      setUserEvaluationsTaken(taken);
      setUserEvaluationsPending(pending);
      setNotionsGrades(average_scores_per_notion);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    refreshEvalData();
  }, []);

  const handleEvaluationClick = (evaluation, isPending) => {
    setSelectedEvaluation(evaluation);
    setIsTakingQuiz(isPending);
    setIsViewingPreviousQuiz(!isPending);
  };

  const handleNotionClick = (notion) => {
    setSelectedNotion(notion);
  };

  const handleBackClick = () => {
    setSelectedEvaluation(null);
    setIsTakingQuiz(false);
    setIsViewingPreviousQuiz(false);
    setSelectedNotion(null);
  };

  return (
    <div className="p-8">
      {selectedEvaluation ? (
        isTakingQuiz ? (
          <TakeQuiz studentId={localStorage.getItem('userId')} quizId={selectedEvaluation.quizz.id} onBackClick={handleBackClick} refreshEvaluations={refreshEvalData} />
        ) : (
          <EvaluationDetails studentId={localStorage.getItem('userId')} quizId={selectedEvaluation.quizz.id} onBackClick={handleBackClick} />
        )
      ) : selectedNotion ? (
        <PracticeQuestions
          notion={selectedNotion.notion}
          questions={selectedNotion.questions}
          onBackClick={handleBackClick}
        />
      ) : (
        <>          
          
          <div className="mb-8">
            <h2>Évaluations à faire</h2>
            {userEvaluationsPending.length > 0 ? (
              userEvaluationsPending.map((evaluation, index) => (
                <div key={index} className='evaluation-card pending'>
                  <div className="flex justify-between items-center">
                      
                      <div>
                        <span className="font-semibold">{evaluation.evaluation}</span>
                        <p className="subtext">Partagée le XX</p>  {/* Date de partage à ajouter dans les données */}
                        <p className="subtext danger">À faire pour le XX</p>  {/* Date de deadline à ajouter dans les données */}
                      </div>

                      <button 
                        onClick={() => handleEvaluationClick(evaluation, true)}
                        className="button-primary"
                        > Commencer
                      </button>
                  </div>
                </div>
              ))
            ) : (
              <p>Aucune évaluation à faire.</p>
            )}
          </div>

          <div className="mb-8">
            <h2>Évaluations déjà faites</h2>
            {userEvaluationsTaken.length > 0 ? (
              userEvaluationsTaken.map((evaluation, index) => (
                <div key={index} className="evaluation-card" onClick={() => handleEvaluationClick(evaluation, false)}>
                  <div className="flex justify-between items-center mb-2">

                    {/* Groupe contenant l'évaluation et la date de partage */}
                    <div className="mb-2">
                      <span className="big-text">{evaluation.evaluation}</span>
                      <p className="subtext">Partagée le XX</p>  {/* Date de partage à ajouter dans les données */}
                    </div>

                    <div className="flex flex-col items-center h-full mb-2">
                      <p className="big-text">XX</p> 
                      <p className="subtext">tentatives</p>
                    </div>
                  </div>

                  <ProgressBar 
                        label="Score moyen"  // Ajout du label "Score moyen"
                        percentage={evaluation.grade}  // Le pourcentage est basé sur le score moyen de l'étudiant
                        color={getProgressBarColor(evaluation.grade)}  // La couleur est définie par la fonction getProgressBarColor
                    />


                  {/* Groupe des deux boutons */}
                  <div className="duo-buttons flex justify-end">
                      <button
                      onClick={() => handleEvaluationClick(evaluation, false)}
                      className="button-outline"
                      > Voir les réponses
                      </button>
                      <button
                        className="button-primary"
                        > Refaire
                      </button>
                    </div>
                </div>
              ))
            ) : (
              <p>Aucune évaluation faite.</p>
            )}
          </div>
          <div>
            <h2>Notes par notion</h2>
            {notionsGrades.length > 0 ? (
              notionsGrades.map((notion, index) => (
                <div key={index} className="evaluation-card by-notion">
                  <div className="flex justify-between items-center mb-2">
                    <div>
                      <p className='notion-type'>Thème</p>
                      <p className="big-text">{notion.notion}</p>
                    </div>
                    <div className="flex flex-col items-center h-full mb-2">
                      <p className="big-text">XX</p>  {/* Date de partage à ajouter dans les données */}
                      <p className="subtext">tentatives</p>
                    </div>
                  </div>
                  <ProgressBar 
                    label="Score moyen"  // Ajout du label "Score moyen"
                    percentage={notion.grade}  // Le pourcentage est basé sur le score moyen de l'étudiant
                    color={getProgressBarColor(notion.grade)}  // La couleur est définie par la fonction getProgressBarColor
                  />
                  <div className='className=duo-buttons flex justify-end'>
                    <button 
                      onClick={() => handleNotionClick(notion)}
                      className="button-primary"
                    > S'entrainer
                    </button>
                  </div>
                  
                </div>
              ))
            ) : (
              <p>Aucune note par notion.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Evaluations;