"use client"

import { ChevronLeft, ChevronRight, ChevronDown } from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import { Button } from "../components/ui/button"

interface StudentSelectorProps {
  students: { id: string; name: string }[]
  selectedStudentId: string
  onSelectStudent: (studentId: string) => void
  onPreviousStudent: () => void
  onNextStudent: () => void
}

export function StudentSelector({
  students,
  selectedStudentId,
  onSelectStudent,
  onPreviousStudent,
  onNextStudent,
}: StudentSelectorProps) {
  return (
    <div className="mb-8 pt-4">
      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="icon"
          onClick={onPreviousStudent}
          className="text-[#1550a7] mt-4"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        
        <div className='flex-1'>
          <p className="subtext">Sélectionner la copie d'un élève</p>
          <Select value={selectedStudentId} onValueChange={onSelectStudent}>
            <SelectTrigger className="flex-1">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {students.map((student) => (
                <SelectItem key={student.id} value={student.id}>
                  {student.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <Button
          variant="ghost"
          size="icon"
          onClick={onNextStudent}
          className="text-[#1550a7] mt-4"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

