// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles pour la sidebar sur desktop */
.sidebar {
    width: 16rem; /* Largeur de la sidebar */
    border-right: 1px solid var(--color-clear); /* Ligne de séparation */
    background-color: #ffff;
    color: var(--color-text); /* Couleur du texte */
    height: 100vh; /* h-screen */
    display: flex; /* flex */
    flex-direction: column; /* flex-col */
    justify-content: space-between; /* justify-between */
  }
  
  /* Cacher la sidebar sur mobile */
  @media (max-width: 768px) {
    .sidebar {
      display: none;
    }
  }
  
  /* Navbar inférieure fixe pour mobile */
  .mobile-navbar {
    display: none;
  }
  
  /* Afficher la navbar sur mobile uniquement */
  @media (max-width: 768px) {
    .mobile-navbar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4rem;
      background-color: #fff;
      border-top: 1px solid #e5e7eb;
      z-index: 1000; /* Assure qu'elle reste au premier plan */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,YAAY,EAAE,0BAA0B;IACxC,0CAA0C,EAAE,wBAAwB;IACpE,uBAAuB;IACvB,wBAAwB,EAAE,qBAAqB;IAC/C,aAAa,EAAE,aAAa;IAC5B,aAAa,EAAE,SAAS;IACxB,sBAAsB,EAAE,aAAa;IACrC,8BAA8B,EAAE,oBAAoB;EACtD;;EAEA,iCAAiC;EACjC;IACE;MACE,aAAa;IACf;EACF;;EAEA,uCAAuC;EACvC;IACE,aAAa;EACf;;EAEA,6CAA6C;EAC7C;IACE;MACE,aAAa;MACb,6BAA6B;MAC7B,mBAAmB;MACnB,eAAe;MACf,SAAS;MACT,OAAO;MACP,QAAQ;MACR,YAAY;MACZ,sBAAsB;MACtB,6BAA6B;MAC7B,aAAa,EAAE,yCAAyC;IAC1D;EACF","sourcesContent":["/* Styles pour la sidebar sur desktop */\n.sidebar {\n    width: 16rem; /* Largeur de la sidebar */\n    border-right: 1px solid var(--color-clear); /* Ligne de séparation */\n    background-color: #ffff;\n    color: var(--color-text); /* Couleur du texte */\n    height: 100vh; /* h-screen */\n    display: flex; /* flex */\n    flex-direction: column; /* flex-col */\n    justify-content: space-between; /* justify-between */\n  }\n  \n  /* Cacher la sidebar sur mobile */\n  @media (max-width: 768px) {\n    .sidebar {\n      display: none;\n    }\n  }\n  \n  /* Navbar inférieure fixe pour mobile */\n  .mobile-navbar {\n    display: none;\n  }\n  \n  /* Afficher la navbar sur mobile uniquement */\n  @media (max-width: 768px) {\n    .mobile-navbar {\n      display: flex;\n      justify-content: space-around;\n      align-items: center;\n      position: fixed;\n      bottom: 0;\n      left: 0;\n      right: 0;\n      height: 4rem;\n      background-color: #fff;\n      border-top: 1px solid #e5e7eb;\n      z-index: 1000; /* Assure qu'elle reste au premier plan */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
