// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.students-list {
    display: flex;
    flex-direction: column;
  }
  
  .student-card {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-top: 0!important;
  }
  
  .student-card:hover {
    background-color: var(--color-background);
  }
  
  .student-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* S'assure que l'élément prend toute la largeur disponible */
  }

  @media (max-width: 760px) {
    .student-info {
      flex-direction: column; /* Change la disposition en colonne */
      align-items: flex-start; /* Alignement à gauche pour éviter que tout soit centré */
      gap: 4px; /* Ajoute un petit espacement entre les éléments */
    }
  }

  .student-main-info{
    min-width: 240px;
}
  
  .student-score {
    display: flex; /* Active Flexbox */
    align-items: center; /* Centre les éléments verticalement */
    gap: 2rem;
    width: 100%;
    justify-content: flex-end; /* Aligne le contenu à droite */
  }
  
  .en-cours-label{
    display :flex;
    align-items: center;
    gap: 4px;
    color: var(--color-subtext);
    font-size: 14px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Evaluation/page/results/page.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,6CAA6C;IAC7C,uBAAuB;EACzB;;EAEA;IACE,yCAAyC;EAC3C;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW,EAAE,6DAA6D;EAC5E;;EAEA;IACE;MACE,sBAAsB,EAAE,qCAAqC;MAC7D,uBAAuB,EAAE,yDAAyD;MAClF,QAAQ,EAAE,kDAAkD;IAC9D;EACF;;EAEA;IACE,gBAAgB;AACpB;;EAEE;IACE,aAAa,EAAE,mBAAmB;IAClC,mBAAmB,EAAE,sCAAsC;IAC3D,SAAS;IACT,WAAW;IACX,yBAAyB,EAAE,+BAA+B;EAC5D;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,2BAA2B;IAC3B,eAAe;EACjB","sourcesContent":[".students-list {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .student-card {\n    cursor: pointer;\n    transition: background-color 0.2s ease-in-out;\n    margin-top: 0!important;\n  }\n  \n  .student-card:hover {\n    background-color: var(--color-background);\n  }\n  \n  .student-info {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%; /* S'assure que l'élément prend toute la largeur disponible */\n  }\n\n  @media (max-width: 760px) {\n    .student-info {\n      flex-direction: column; /* Change la disposition en colonne */\n      align-items: flex-start; /* Alignement à gauche pour éviter que tout soit centré */\n      gap: 4px; /* Ajoute un petit espacement entre les éléments */\n    }\n  }\n\n  .student-main-info{\n    min-width: 240px;\n}\n  \n  .student-score {\n    display: flex; /* Active Flexbox */\n    align-items: center; /* Centre les éléments verticalement */\n    gap: 2rem;\n    width: 100%;\n    justify-content: flex-end; /* Aligne le contenu à droite */\n  }\n  \n  .en-cours-label{\n    display :flex;\n    align-items: center;\n    gap: 4px;\n    color: var(--color-subtext);\n    font-size: 14px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
