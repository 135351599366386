import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EvaluationDetails from './EvaluationDetails';
import TakeQuiz from './TakeQuiz';
import PracticeQuestions from './PracticeQuestions';
import '../Title.css'; // Importation du fichier CSS
import '../Button.css'; // Importation du fichier CSS
import './StudentEvaluation.css';
import ProgressBar from '../ProgressBar';
import { BACKEND_URL } from '../../config';
import { Zap } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import { useSearchParams, useNavigate } from 'react-router-dom';

const getProgressBarColor = (score) => {
  if (score > 60) return 'var(--color-success)';
  if (score > 30) return 'var(--color-warning)';
  return 'var(--color-danger)';
};

const Evaluations = () => {
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [isTakingQuiz, setIsTakingQuiz] = useState(false);
  const [isViewingPreviousQuiz, setIsViewingPreviousQuiz] = useState(false);
  const [selectedNotion, setSelectedNotion] = useState(null);
  const [userEvaluationsTaken, setUserEvaluationsTaken] = useState([]);
  const [userEvaluationsPending, setUserEvaluationsPending] = useState([]);
  const [notionsGrades, setNotionsGrades] = useState({});
  const navigate = useNavigate();

  // I want to read the search params of the page, because it contains the state of the showSuccessToast
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const showToast = searchParams.get('showSuccessToast') === 'true';
    if (showToast) {
      toast.success("Réponses enregistrées avec succès", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // Clean up URL parameter after showing toast
      navigate('.', { replace: true });
    }
  }, [searchParams, navigate]);

  const refreshEvalData = async () => {
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/student/trialquizzes`, {
        params: {
          student_user_id: userId
        }
      });
      const { trial_quizzes, average_scores_per_notion } = response.data;
  
      const taken = trial_quizzes.filter(quiz => quiz.attempted);
      const pending = trial_quizzes.filter(quiz => !quiz.attempted);
  
      setUserEvaluationsTaken(taken);
      setUserEvaluationsPending(pending);
      setNotionsGrades(average_scores_per_notion);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    refreshEvalData();
  }, []);

  const handleEvaluationClick = (evaluation, isPending) => {
    setSelectedEvaluation(evaluation);
    setIsTakingQuiz(isPending);
    setIsViewingPreviousQuiz(!isPending);
  };

  const handleNotionClick = (notion) => {
    setSelectedNotion(notion);
  };

  const handleBackClick = () => {
    setSelectedEvaluation(null);
    setIsTakingQuiz(false);
    setIsViewingPreviousQuiz(false);
    setSelectedNotion(null);
  };

  return (
    <div className="p-8">
      <ToastContainer />
      {selectedEvaluation ? (
        isTakingQuiz ? (
          <TakeQuiz 
            studentId={localStorage.getItem('userId')} 
            quizId={selectedEvaluation.quizz.id} 
            onBackClick={handleBackClick} 
            refreshEvaluations={refreshEvalData} 
          />
        ) : (
          <EvaluationDetails studentId={localStorage.getItem('userId')} quizId={selectedEvaluation.quizz.id} onBackClick={handleBackClick} />
        )
      ) : selectedNotion ? (
        (
          <PracticeQuestions
            notion={selectedNotion.notion}
            notion_id={selectedNotion.notion_id} // Add this line
            questions={selectedNotion.questions}
            onBackClick={handleBackClick}
          />
        )
      ) : (
        <>
          <div className="mb-8">
            <h1>Mes évaluations</h1>
            <h2>Évaluations à faire</h2>
            {userEvaluationsPending.length > 0 ? (
              userEvaluationsPending.map((evaluation, index) => (
                <div key={index} className='evaluation-container pending'>
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="big-text">{evaluation.quizz.name}</span>
                      <p className="subtext">Partagée le {new Date(evaluation.shared_on).toLocaleDateString()}</p>
                      <p className="subtext danger">À faire pour le {evaluation.deadline ? new Date(evaluation.deadline).toLocaleDateString() : 'N/A'}</p>
                    </div>
                    <button 
                      onClick={() => handleEvaluationClick(evaluation, true)}
                      className="button-primary"
                    > Commencer
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className='small-text'>Aucune évaluation à faire pour le moment</p>
            )}
          </div>

          <div className="mb-8">
            <h2>Évaluations passées</h2>
            {userEvaluationsTaken.length > 0 ? (
              userEvaluationsTaken.map((evaluation, index) => (
                <div key={index} className="evaluation-container evaluation-done" onClick={() => handleEvaluationClick(evaluation, false)}>
                  <div className="evaluation-info-container">
                    <div className="evaluation-title">
                      <p className='big-text'>{evaluation.quizz.name}</p>
                      <p className='small-text'>{evaluation.quizz.teacher_first_name} {evaluation.quizz.teacher_last_name}</p>
                      <p className="subtext">Partagée le {new Date(evaluation.shared_on).toLocaleDateString()}</p>
                    </div>
                    <div className='evaluation-stats'>
                      <div className="progress-bar-evaluation-results">
                        <ProgressBar 
                          label="Score moyen"  // Ajout du label "Score moyen"
                          percentage={evaluation.score || 0}  // Le pourcentage est basé sur le score moyen de l'étudiant
                          color={getProgressBarColor(evaluation.score || 0)}  // La couleur est définie par la fonction getProgressBarColor
                        />
                      </div>
                      <div className="attempts-column">
                        <p className="subtext text-attempts-nb">Nombre de tentatives</p>
                        <div className='attempts-nb'>
                          <p className="big-text">{evaluation.attemps}</p> 
                          <Zap size={16}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className='small-text'>Aucune évaluation faite</p>
            )}
          </div>
          <div>
            <h2>Statistiques par notion</h2>
            {Object.keys(notionsGrades).map((notion, index) => (
              <div key={index} className="evaluation-container by-notion">
                <div className="evaluation-info-container">
                  <div className="evaluation-title">
                    <p className="big-text">{notion}</p>
                  </div>
                  <div className='evaluation-stats'>
                    <div className="progress-bar-evaluation-results">
                      <ProgressBar 
                        label="Score moyen"
                        percentage={notionsGrades[notion].score * 100}
                        color={getProgressBarColor(notionsGrades[notion].score * 100)}
                        />
                    </div>
                    <div className="attempts-column">
                      <p className="subtext text-attempts-nb">Nombre de tentatives</p>
                      <div className='attempts-nb'>
                        <p className="big-text">{notionsGrades[notion].score}</p>
                        <Zap size={16}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='className=duo-buttons flex justify-end'>
                  <button 
                    onClick={() => handleNotionClick({ 
                      notion, 
                      notion_id: notionsGrades[notion].id,
                      questions: [] 
                    })}
                    className="button-primary"
                  > 
                    Réviser
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Evaluations;