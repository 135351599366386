import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BACKEND_URL } from '../../config';

const SupportPage = () => {
  const [issueType, setIssueType] = useState('');
  const [details, setDetails] = useState('');
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedUserType = localStorage.getItem('userType');
    setUserId(storedUserId);
    setUserType(storedUserType);
  }, []);

  const handleIssueTypeChange = (event) => {
    setIssueType(event.target.value);
  };

  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validation des champs
    if (!issueType || !details) {
      toast.error('Veuillez remplir tous les champs.');
      return;
    }
    try {
      const response = await axios.post(`${BACKEND_URL}/backapp/help/`, {
        issueType: issueType,
        userId: userId,
        details: details,
      });
      console.log('Réponse du serveur:', response.data);
      toast.success('Votre demande de support a été envoyée avec succès !');
    } catch (error) {
      console.error('Erreur lors de l\'envoi des données:', error);
      toast.error('Une erreur est survenue lors de l\'envoi de votre demande de support.');
    }
  };

  return (
    <div className="p-8">
      <h2>Aide et Recommandation</h2>
      <p>Tu as trouvé un bug, tu as besoin d'aide ou bien tu as pensé à une idée de fonctionnalités à rajouter sur Edjoy ?  </p>
      <p className='mb-4'> Partage nous ton message ici et nous le traiterons au plus vite ! 😊</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="subtext">Type de signalement</label>
          <select
            value={issueType}
            onChange={handleIssueTypeChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Sélectionnez une option</option>
            <option value="bug">J'ai trouvé un bug ou bien j'ai besoin d'aide</option>
            <option value="recommendation">J'ai une super idée de fonctionnalité à rajouter sur Edjoy</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="subtext">Détails</label>
          <textarea
            value={details}
            onChange={handleDetailsChange}
            className="w-full p-2 border border-gray-300 rounded"
            rows="5"
            placeholder="Décris ton problème, le bug que tu as rencontré ou bien ta recommandation ici..."
          ></textarea>
        </div>
        <button
          type="submit"
          className="button-primary mx-auto mt-8"
        >
          Envoyer
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default SupportPage;