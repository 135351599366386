// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notion-tag{
    background-color: white; /* bg-gray-200 : Couleur de fond gris clair */
    color: var(--color-subtext);            /* text-gray-700 : Couleur du texte gris foncé */
    border-radius: 9999px;    /* rounded-full : Arrondir complètement (bords arrondis) */
    padding-left: 1rem;       /* px-4 : Padding horizontal de 1rem (16px) */
    padding-right: 1rem;      /* px-4 : Padding horizontal de 1rem (16px) */
    padding-top: 0.5rem;      /* py-2 : Padding vertical de 0.5rem (8px) */
    padding-bottom: 0.5rem;   /* py-2 : Padding vertical de 0.5rem (8px) */
    margin-right: 0.5rem;     /* mr-2 : Marge à droite de 0.5rem (8px) */
    margin-bottom: 0.5rem;    /* mb-2 : Marge en bas de 0.5rem (8px) */
  }

.question-saved {
    padding: 1rem; /* équivalent à 16px */
    margin-top: 1rem;
    position: relative;            /* relative : Positionnement relatif */
    border-bottom: 1px solid var(--color-clear);
}

`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Evaluation/QuizCreation.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB,EAAE,6CAA6C;IACtE,2BAA2B,aAAa,gDAAgD;IACxF,qBAAqB,KAAK,0DAA0D;IACpF,kBAAkB,QAAQ,6CAA6C;IACvE,mBAAmB,OAAO,6CAA6C;IACvE,mBAAmB,OAAO,4CAA4C;IACtE,sBAAsB,IAAI,4CAA4C;IACtE,oBAAoB,MAAM,0CAA0C;IACpE,qBAAqB,KAAK,wCAAwC;EACpE;;AAEF;IACI,aAAa,EAAE,sBAAsB;IACrC,gBAAgB;IAChB,kBAAkB,aAAa,sCAAsC;IACrE,2CAA2C;AAC/C","sourcesContent":[".notion-tag{\n    background-color: white; /* bg-gray-200 : Couleur de fond gris clair */\n    color: var(--color-subtext);            /* text-gray-700 : Couleur du texte gris foncé */\n    border-radius: 9999px;    /* rounded-full : Arrondir complètement (bords arrondis) */\n    padding-left: 1rem;       /* px-4 : Padding horizontal de 1rem (16px) */\n    padding-right: 1rem;      /* px-4 : Padding horizontal de 1rem (16px) */\n    padding-top: 0.5rem;      /* py-2 : Padding vertical de 0.5rem (8px) */\n    padding-bottom: 0.5rem;   /* py-2 : Padding vertical de 0.5rem (8px) */\n    margin-right: 0.5rem;     /* mr-2 : Marge à droite de 0.5rem (8px) */\n    margin-bottom: 0.5rem;    /* mb-2 : Marge en bas de 0.5rem (8px) */\n  }\n\n.question-saved {\n    padding: 1rem; /* équivalent à 16px */\n    margin-top: 1rem;\n    position: relative;            /* relative : Positionnement relatif */\n    border-bottom: 1px solid var(--color-clear);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
