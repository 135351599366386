import { Trash2, UserRound } from 'lucide-react'
import { Button } from './ui/button'
import { Card } from './ui/card'
import type { Evaluation } from '../types/evaluation'
import { useNavigate } from 'react-router-dom'
import './evaluation-card.css'



interface ClassType {
  id: number;
  name: string;
}

interface EvaluationCardProps {
  evaluation: Evaluation;
  onShare: (id: string) => void;
  onModify: (id: string) => void;
  onDelete: (id: string) => void;
  showProgress?: boolean;
}

export function EvaluationCard({ evaluation, onShare, onModify, onDelete, showProgress }: EvaluationCardProps) {
  const navigate = useNavigate()
  const handleModify = () => {
    navigate(`/evaluation/generate?id=${evaluation.id}`)
  }
  const handleExamine = () => {
    console.log('Examining evaluation:', evaluation.id);
    navigate(`/evaluation/results/${evaluation.id}`);
  }
  return (
    <div className="evaluation-card-container">
      <div className='topline'>
        <div className='title-container'>
          <p className='big-text'>
            {evaluation.title}
          </p>
          {!showProgress && (
          <p className="subtext">Créée le {evaluation.date}</p>
          )}
        </div>
        { !showProgress && (
        <Button
          variant="ghost"
          className="button-icon-danger small-screen-element"
          onClick={() => onDelete(evaluation.id)}
          >
          <Trash2 size={16}/>
        </Button>
        )}
      </div>
          {evaluation.classes?.map((class_: ClassType) => (
          <div  
            className='assessed-class-container'               
            onClick={handleExamine}
          >
            <div className='assessed-class-stats'>
              <span 
                key={class_.id} 
                className="text"
              >
                {class_.name}
              </span>

              {showProgress && (
              <div className="stats-container">
                {(evaluation.studentCount ?? 0) > 0 ? (
                <>
                <div className="subtext flex items-center gap-0.5">
                  <p className="subtext">
                    {evaluation.studentCount}/{evaluation.totalStudents}
                  </p>
                  <UserRound size={12}/>
                </div>
                <div className={`subtext ${
                  evaluation.averageScore !== undefined
                  ? evaluation.averageScore < 30
                  ? 'average-low'
                  : evaluation.averageScore < 70
                  ? 'average-medium'
                  : 'average-high'
                  : ''
                }`}>
                  {/* Display overall average score */}
                  Moyenne : {Math.round(Number(evaluation.averageScore?.toFixed(1))) ?? "0.0"}%
                </div>
                </>
                ) : (
                <div className="subtext flex items-center gap-0.5">
                  <p className="subtext">
                    0/{evaluation.totalStudents}
                  </p>
                  <UserRound size={12}/>
                </div>
                )}
                </div>
              )}
            </div>
            <p className="subtext">{evaluation.date}</p>
          </div>
          ))}

      <div className="flex gap-2">
        { !showProgress && (
          <>
        <Button
          variant="outline"
          className="small-button-outline"
          onClick={handleModify}
          >
          Modifier
        </Button>
        <Button
          variant="default"
          className="small-button-primary"
          onClick={() => onShare(evaluation.id)}
          >
          Partager
        </Button>
        <Button
          variant="ghost"
          className="button-icon-danger large-screen-element"
          onClick={() => onDelete(evaluation.id)}
          >
          <Trash2 size={16}/>
        </Button>
          </>
       )}
      </div>
    </div>
  )
}
