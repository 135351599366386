import React from 'react';

const ShareModal = ({ isOpen, onClose, onValidate, classes, selectedClasses, setSelectedClasses }) => {
  if (!isOpen) return null;

  const handleClassChange = (className) => {
    if (selectedClasses.includes(className)) {
      setSelectedClasses(selectedClasses.filter(c => c !== className));
    } else {
      setSelectedClasses([...selectedClasses, className]);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2>Partager l'évaluation</h2>
        <div className="mb-8">
          {classes.map(className => (
            <div key={className} className="flex items-center mb-2">
              <input
                type="checkbox"
                className="mr-2"
                checked={selectedClasses.includes(className)}
                onChange={() => handleClassChange(className)}
              />
              <label>{className}</label>
            </div>
          ))}
        </div>

        <div className="flex justify-center"style={{ gap: '1rem' }}>
          <button
            onClick={onClose}
            className="button-outline"
          >
            Annuler
          </button>
          <button
            onClick={onValidate}
            className="button-primary"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;