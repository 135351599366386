import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../config';
import './StudentProfile.css'


const Profile = () => {
  const [userData, setUserData] = useState(null);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/` + localStorage.getItem('userType') + '/' + localStorage.getItem('userId') + '/');
      setUserData(response.data); // Store the fetched data in the state
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const { first_name, last_name, school, email, classes } = userData;

  return (
    <div className="body">
      <div className="user-profile-info-container background-primary">
        <div>
          {userData.userProfilePic ? (
            <img src={userData.userProfilePic} alt="Profile" className="profile-picture" />
            ) : (
              <div className="avatar-container">
                {first_name[0].toUpperCase()}{last_name[0].toUpperCase()}
              </div>
            )}
        </div>
        <div className='user-details'>
          <h2>{first_name} {last_name}</h2>
          <ul className="big-text">
            {classes.map((classItem, index) => (
              <li key={index}>{classItem.name}</li>
            ))}
          </ul>
          <div className="small-text">{school}</div>
          <p className="subtext">{localStorage.getItem('email')}</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;