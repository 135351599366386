// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.indication-text {
    color: var(--color-subtext);
    font-size: 16px;
}

.selector-container {
    width: 100%;               /* Correspond à w-full */
    padding: 0.5rem;           /* Correspond à p-2, ici 0.5rem = 8px */
    border: 1px solid var(--color-clear); /* Correspond à border + border */
    border-radius: 0.25rem;     /* Correspond à rounded (4px de rayon) */
    color: var(--color-text);
    font-size: 16px;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.selector-container:focus{
    outline: none; /* Désactive l'outline par défaut du navigateur */
    border: 1.5px solid var(--color-primary); /* Correspond à border + border */
}`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Evaluation/Selector.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,WAAW,gBAAgB,wBAAwB;IACnD,eAAe,YAAY,uCAAuC;IAClE,oCAAoC,EAAE,iCAAiC;IACvE,sBAAsB,MAAM,wCAAwC;IACpE,wBAAwB;IACxB,eAAe;IACf,sBAAsB;IACtB,mBAAmB;IACnB,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,aAAa,EAAE,iDAAiD;IAChE,wCAAwC,EAAE,iCAAiC;AAC/E","sourcesContent":[".indication-text {\n    color: var(--color-subtext);\n    font-size: 16px;\n}\n\n.selector-container {\n    width: 100%;               /* Correspond à w-full */\n    padding: 0.5rem;           /* Correspond à p-2, ici 0.5rem = 8px */\n    border: 1px solid var(--color-clear); /* Correspond à border + border */\n    border-radius: 0.25rem;     /* Correspond à rounded (4px de rayon) */\n    color: var(--color-text);\n    font-size: 16px;\n    margin-bottom: 0.25rem;\n    margin-top: 0.25rem;\n    margin-left: 0.25rem;\n    margin-right: 0.25rem;\n}\n\n.selector-container:focus{\n    outline: none; /* Désactive l'outline par défaut du navigateur */\n    border: 1.5px solid var(--color-primary); /* Correspond à border + border */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
