import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronLeft } from 'lucide-react';
import { Button } from "./components/button"
import { Card, CardContent, CardHeader, CardTitle } from "./components/card"
import { Checkbox } from "./components/checkbox"
import '../././Background.css';
import { useNavigate } from 'react-router-dom';


import { BACKEND_URL} from '../../config'; // Update this if your backend runs on a different port

interface Answer {
  answer: string;
  isTrue: boolean;
}

interface FormattedAnswer {
  question_id: number;
  selected_answers: string[];
  is_correct: boolean;
}

interface FormattedAnswers {
  [key: string]: FormattedAnswer;
}


interface Question {
  id: number;
  question: string;
  answers: {
    [key: string]: Answer;
  };
}

interface QuizData {
  quiz: {
    id: number;
    quizz: {
      id: number;
      name: string;
      teacher_first_name: string;
      teacher_last_name: string;
    };
    student: number;
    score: number;
  };
  questions: Array<{
    id: number;
    question: string;
    answers: Record<string, { answer: string; isTrue: boolean }>;
  }>;
}

interface SelectedAnswer {
  id: number;
  selectedAnswers: string[];
  isCorrect: boolean;
}

interface TakeQuizProps {
  studentId: number;
  quizId: number;
  onBackClick: () => void;
  refreshEvaluations: () => void;
}

const TakeQuiz: React.FC<TakeQuizProps> = ({ studentId, quizId, onBackClick, refreshEvaluations }) => {
  const [quizData, setQuizData] = useState<QuizData | null>(null);
  const [selectedAnswers, setSelectedAnswers] = useState<{ [key: number]: SelectedAnswer }>({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/student/take_quiz`, {
          params: {
            student_user_id: studentId,
            quiz_id: quizId
          }
        });
        setQuizData(response.data);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      }
    };
  
    fetchQuizData();
  }, [studentId, quizId]);

  const handleAnswerChange = (questionId: number, answerKey: string) => {
    if (!quizData) return;
    
    const question = quizData.questions.find(q => q.id === questionId);
    if (!question) return;
    
    setSelectedAnswers((prevSelectedAnswers) => {
      const newAnswers = { ...prevSelectedAnswers };
      
      if (!newAnswers[questionId]) {
        newAnswers[questionId] = {
          id: questionId,
          selectedAnswers: [],
          isCorrect: false
        };
      }
      
      const currentAnswers = [...newAnswers[questionId].selectedAnswers];
      if (currentAnswers.includes(answerKey)) {
        newAnswers[questionId].selectedAnswers = currentAnswers.filter(a => a !== answerKey);
      } else {
        newAnswers[questionId].selectedAnswers = [...currentAnswers, answerKey];
      }
      
      const correctAnswers = Object.entries(question.answers)
        .filter(([_, value]) => value.isTrue)
        .map(([key]) => key);
      
      newAnswers[questionId].isCorrect = 
        correctAnswers.length === newAnswers[questionId].selectedAnswers.length &&
        correctAnswers.every(a => newAnswers[questionId].selectedAnswers.includes(a));
      
      return newAnswers;
    });
  };

  const handleSubmit = async () => {
    try {
      const formattedAnswers = Object.entries(selectedAnswers).reduce<FormattedAnswers>((acc, [questionId, answerData]) => {
        acc[questionId] = {
          question_id: answerData.id,
          selected_answers: answerData.selectedAnswers,
          is_correct: answerData.isCorrect
        };
        return acc;
      }, {});

      console.log('Submitting quiz with payload:', {
        body: { answers: formattedAnswers },
        params: {
          student_user_id: studentId,
          quiz_id: quizId
        }
      });
  
      const response = await axios.post(`${BACKEND_URL}/backapp/student/submit-quiz`, {
        answers: formattedAnswers
      }, {
        params: {
          student_user_id: studentId,
          quiz_id: quizId
        }
      });
      onBackClick();
      navigate('/evaluations?showSuccessToast=true');
      refreshEvaluations();
      
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  };

  if (!quizData) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="mb-16">
      <div className="flex justify-start">
        <Button 
          onClick={onBackClick} 
          className="button-clear"
          >
          <ChevronLeft size={16}/> 
          Retour
        </Button>
      </div>
      
      <Card className="background-primary">
        <CardHeader>
          <h2>{quizData?.quiz?.quizz?.name}</h2>
        </CardHeader>
      </Card>

      {quizData?.questions?.map((question, qIndex) => (
        <Card key={question.id} className="mb-6">
          <CardHeader>
            <h3>
            Question {qIndex+1}
            </h3>
          </CardHeader>
          <CardContent>
            <p className="question-text">{question.question}</p>
            <div className='answers-group-container'>
              {Object.entries(question.answers).map(([key, answer], index) => (
                <div key={key} 
                className="answer-container">
                  <div className={`answer-number answer-number-${index + 1}`}>
                  {String.fromCharCode(65 + index)}
                  </div>
                  <label
                    htmlFor={`question-${question.id}-answer-${key}`}
                    className={`answer-content ${selectedAnswers[question.id]?.selectedAnswers.includes(key) ? 'checked' : 'not-shown'}`}
                  >
                    {answer.answer}
                  </label>
                  <Checkbox
                    id={`question-${question.id}-answer-${key}`}
                    checked={selectedAnswers[question.id]?.selectedAnswers.includes(key)}
                    onCheckedChange={() => handleAnswerChange(question.id, key)}
                  />
                  
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      ))}
      
      <div className="flex justify-end mt-12">
        <Button onClick={handleSubmit} className='button-primary'>
          Soumettre
        </Button>
      </div>
    </div>
  );
};

export default TakeQuiz;

