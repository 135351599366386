import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SupportPage from './Support/SupportPage';
import Profile from './Student/StudentProfile';
import Evaluations from './Student/StudentEvaluations';

const StudentRoutes = (userData) => {
  return (
    <Routes>
      <Route path="/" element={<Profile />} />
      <Route path="/help" element={<SupportPage />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/evaluations" element={<Evaluations />} />
    </Routes>
  );
};

export default StudentRoutes;