// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-primary {
    background-color: var(--color-background); /* couleur de fond bleu clair */    
    padding: 1rem; /* équivalent à 16px */
    border-radius: 0.5rem; /* équivalent à 8px */
    margin-bottom: 2rem;
    margin-top: 2rem;
    position: relative;            /* relative : Positionnement relatif */
}

.background-secondary {
    background-color: var(--color-background-secondary); /* couleur de fond bleu clair */    
    padding: 1rem; /* équivalent à 16px */
    border-radius: 0.5rem; /* équivalent à 8px */
    margin-bottom: 2rem;
    margin-top: 2rem;
    position: relative;            /* relative : Positionnement relatif */
}

.background-transparent {
    padding: 1rem; /* équivalent à 16px */
    border-radius: 0.5rem; /* équivalent à 8px */
    margin-bottom: 2rem;
    margin-top: 2rem;
    position: relative;            /* relative : Positionnement relatif */
    border-bottom: 1px var(--color-clear);
}
`, "",{"version":3,"sources":["webpack://./src/components/Background.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC,EAAE,+BAA+B;IAC1E,aAAa,EAAE,sBAAsB;IACrC,qBAAqB,EAAE,qBAAqB;IAC5C,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB,aAAa,sCAAsC;AACzE;;AAEA;IACI,mDAAmD,EAAE,+BAA+B;IACpF,aAAa,EAAE,sBAAsB;IACrC,qBAAqB,EAAE,qBAAqB;IAC5C,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB,aAAa,sCAAsC;AACzE;;AAEA;IACI,aAAa,EAAE,sBAAsB;IACrC,qBAAqB,EAAE,qBAAqB;IAC5C,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB,aAAa,sCAAsC;IACrE,qCAAqC;AACzC","sourcesContent":[".background-primary {\n    background-color: var(--color-background); /* couleur de fond bleu clair */    \n    padding: 1rem; /* équivalent à 16px */\n    border-radius: 0.5rem; /* équivalent à 8px */\n    margin-bottom: 2rem;\n    margin-top: 2rem;\n    position: relative;            /* relative : Positionnement relatif */\n}\n\n.background-secondary {\n    background-color: var(--color-background-secondary); /* couleur de fond bleu clair */    \n    padding: 1rem; /* équivalent à 16px */\n    border-radius: 0.5rem; /* équivalent à 8px */\n    margin-bottom: 2rem;\n    margin-top: 2rem;\n    position: relative;            /* relative : Positionnement relatif */\n}\n\n.background-transparent {\n    padding: 1rem; /* équivalent à 16px */\n    border-radius: 0.5rem; /* équivalent à 8px */\n    margin-bottom: 2rem;\n    margin-top: 2rem;\n    position: relative;            /* relative : Positionnement relatif */\n    border-bottom: 1px var(--color-clear);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
