// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackgroundProfile {
    background-color: var(--color-background); /* couleur de fond bleu clair */    
    padding: 1rem; /* équivalent à 16px */
    border-radius: 0.5rem; /* équivalent à 8px */
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:2rem;
}

.avatar-container {
  width: 6rem; /* Largeur de 24 * 0.25rem = 6rem */
  height: 6rem; /* Hauteur de 24 * 0.25rem = 6rem */
  border-radius: 50%; /* Cercle parfait */
  background-color: var(--color-clear); /* Couleur de fond gris clair (équivalent bg-gray-300) */
  display: flex; /* Flexbox pour aligner le contenu */
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
  font-size: 1.875rem; /* Taille de police équivalente à text-3xl (30px) */
  font-weight: bold; /* Texte en gras */
  color: white; /* Couleur du texte gris foncé (équivalent text-gray-700) */
}

@media (max-width: 400px) {

  .avatar-container {
      margin: 0 auto; /* Applique mx-auto uniquement en dessous de 768px */
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Profile/Profile.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC,EAAE,+BAA+B;IAC1E,aAAa,EAAE,sBAAsB;IACrC,qBAAqB,EAAE,qBAAqB;IAC5C,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,QAAQ;AACZ;;AAEA;EACE,WAAW,EAAE,mCAAmC;EAChD,YAAY,EAAE,mCAAmC;EACjD,kBAAkB,EAAE,mBAAmB;EACvC,oCAAoC,EAAE,wDAAwD;EAC9F,aAAa,EAAE,oCAAoC;EACnD,mBAAmB,EAAE,yBAAyB;EAC9C,uBAAuB,EAAE,2BAA2B;EACpD,mBAAmB,EAAE,mDAAmD;EACxE,iBAAiB,EAAE,kBAAkB;EACrC,YAAY,EAAE,2DAA2D;AAC3E;;AAEA;;EAEE;MACI,cAAc,EAAE,oDAAoD;EACxE;AACF","sourcesContent":[".BackgroundProfile {\n    background-color: var(--color-background); /* couleur de fond bleu clair */    \n    padding: 1rem; /* équivalent à 16px */\n    border-radius: 0.5rem; /* équivalent à 8px */\n    margin-bottom: 2rem;\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    gap:2rem;\n}\n\n.avatar-container {\n  width: 6rem; /* Largeur de 24 * 0.25rem = 6rem */\n  height: 6rem; /* Hauteur de 24 * 0.25rem = 6rem */\n  border-radius: 50%; /* Cercle parfait */\n  background-color: var(--color-clear); /* Couleur de fond gris clair (équivalent bg-gray-300) */\n  display: flex; /* Flexbox pour aligner le contenu */\n  align-items: center; /* Centre verticalement */\n  justify-content: center; /* Centre horizontalement */\n  font-size: 1.875rem; /* Taille de police équivalente à text-3xl (30px) */\n  font-weight: bold; /* Texte en gras */\n  color: white; /* Couleur du texte gris foncé (équivalent text-gray-700) */\n}\n\n@media (max-width: 400px) {\n\n  .avatar-container {\n      margin: 0 auto; /* Applique mx-auto uniquement en dessous de 768px */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
