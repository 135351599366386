import { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Plus } from 'lucide-react'
import { Button } from '../components/ui/button'
import axios from 'axios'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select'
import { EvaluationSection } from '../components/evaluation-section'
import { ClassSelectionModal } from '../components/class-selection-modal'
import type { Evaluation } from '../types/evaluation'
import { BACKEND_URL } from '../../../../config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface SharedEvaluation {
  id: string;
  title: string;
  date: string;
  sharedOn: Date;
  completion: number;
  studentCount: number;
  totalStudents: number;
  averageScore: number;
  classes: Array<{
    id: number;
    name: string;
  }>;
}

interface Class {
  id: string;
  name: string;
}

export default function EvaluationsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedEvaluationId, setSelectedEvaluationId] = useState<string | null>(null)
  const [years, setYears] = useState<{ id: number; name: string }[]>([])
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined)
  const [inProgressEvaluations, setInProgressEvaluations] = useState<Evaluation[]>([])
  const navigate = useNavigate()
  const location = useLocation();
  const [sharedEvaluations, setSharedEvaluations] = useState<Evaluation[]>([])
  const [availableClasses, setAvailableClasses] = useState<Class[]>([]);

  useEffect(() => {
    if (location.state?.showSaveSuccess) {
      toast.success("Évaluation enregistrée avec succès", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [location]);
  
  useEffect(() => {
    fetchYears()
  }, [])

  useEffect(() => {
    if (selectedYear) {
      fetchEvaluationsInPreparation()
      fetchSharedEvaluations();
    }
  }, [selectedYear])

  const fetchYears = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/years/${localStorage.getItem('userId')}/`)
      console.log('Years fetched:', response.data)
      setYears(response.data)
      
      if (response.data && response.data.length > 0) {
        setSelectedYear(response.data[0].id)
      }
    } catch (error) {
      console.error('Error fetching years:', error)
    }
  }

  const fetchEvaluationsInPreparation = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/evaluations/inpreperation`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          year_id: selectedYear,
        },
      })
      console.log('Evaluations in preparation fetched:', response.data)
      setInProgressEvaluations(response.data.map((evaluation: any) => ({
        id: evaluation.id.toString(),
        title: evaluation.name,
        date: new Date(evaluation.created_on).toLocaleDateString(),
      })))
    } catch (error) {
      console.error('Error fetching evaluations in preparation:', error)
    }
  }

  const fetchSharedEvaluations = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/shared_quizzes`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          year_id: selectedYear,
        },
      });
      
      setSharedEvaluations(response.data.shared_quizzes
        .map((quiz: any) => {
          const totalStudentsAcrossClasses = quiz.overall_stats.total_students_accross_classes;
          const studentsAnswered = quiz.overall_stats.total_students_answered;
          const averageScore = quiz.overall_stats.overall_average;
          
          return {
            id: quiz.id.toString(),
            title: quiz.name,
            date: `Partagée le ${new Date(quiz.classes[0]?.shared_on).toLocaleDateString()}`,
            sharedOn: new Date(quiz.classes[0]?.shared_on),
            studentCount: studentsAnswered,
            totalStudents: totalStudentsAcrossClasses,
            averageScore: averageScore,
            classes: quiz.classes.map((c: { id: number; name: string }) => ({
              id: c.id,
              name: c.name
            }))
          };
        })
        .sort((a: SharedEvaluation, b: SharedEvaluation) => b.sharedOn.getTime() - a.sharedOn.getTime())
      );

    } catch (error) {
      console.error('Error fetching shared evaluations:', error);
    }
  };

  const fetchAvailableClasses = async (quizId: string) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/classes_to_share`, {
        params: {
          quiz_id: quizId,
          teacher_id: localStorage.getItem('userId')
        }
      });
      // Convert number ids to strings when setting state
      setAvailableClasses(response.data.classes.map((cls: any) => ({
        ...cls,
        id: cls.id.toString()
      })));
    } catch (error) {
      console.error('Error fetching available classes:', error);
      toast.error("Erreur lors de la récupération des classes");
    }
  };

  const handleShare = (id: string) => {
    setSelectedEvaluationId(id);
    fetchAvailableClasses(id);
    setIsModalOpen(true);
  };
  

  const handleModify = (id: string) => {
    console.log('Modify evaluation:', id)
  }

  const handleDelete = async (id: string) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/backapp/teacher/delete_evaluation`, {
        quiz_id: id,
        teacher_id: localStorage.getItem('userId'),
        _method: 'DELETE'
      });
  
      if (response.status === 204 || response.status === 200) {
        await Promise.all([
          fetchEvaluationsInPreparation(),
          fetchSharedEvaluations()
        ]);
        
        toast.success("Évaluation supprimée avec succès", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting evaluation:', error);
      toast.error("Erreur lors de la suppression de l'évaluation", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleCreateNew = () => {
    console.log('Create new evaluation with year:', selectedYear)
    navigate('/evaluation/new?year_id=' + selectedYear)
  }

  const handleConfirmShare = async (selectedClasses: string[]) => {
    try {
      await axios.post(`${BACKEND_URL}/backapp/teacher/share_quiz`, {
        quiz_id: selectedEvaluationId,
        teacher_id: localStorage.getItem('userId'),
        class_ids: selectedClasses
      });
      
      // Refresh both lists
      await Promise.all([
        fetchEvaluationsInPreparation(),
        fetchSharedEvaluations()
      ]);
      
      toast.success("Évaluation partagée avec succès", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error sharing quiz:', error);
      toast.error("Erreur lors du partage de l'évaluation", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="container mx-auto py-8 px-8">
      <ToastContainer />
      <h1>Mes évaluations</h1>
      
      <div className="space-y-6">
        <div className="w-full">
          <label className="subtext">
            Sélectionne un niveau
          </label>
          <Select
            value={selectedYear?.toString()} 
            onValueChange={(value) => setSelectedYear(Number(value))}
          >
            <SelectTrigger className="w-full max-w-sm">
              <SelectValue placeholder="Sélectionner un niveau" />
            </SelectTrigger>
            <SelectContent>
              {years.map((year) => (
                <SelectItem key={year.id} value={year.id.toString()}>
                  {year.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <Button
          onClick={handleCreateNew}
          className="button-primary"
        >
          <Plus size={16} />
          Créer une nouvelle évaluation
        </Button>

        <EvaluationSection
          title="Évaluations en préparation"
          evaluations={inProgressEvaluations}
          onShare={handleShare}
          onModify={handleModify}
          onDelete={handleDelete}
        />

        <EvaluationSection
          title="Évaluations partagées"
          evaluations={sharedEvaluations}
          onShare={handleShare}
          onModify={handleModify}
          onDelete={handleDelete}
        />
      </div>

      <ClassSelectionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmShare}
        classes={availableClasses}
      />
    </div>
  )
}