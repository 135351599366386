import React, { useState, useEffect } from 'react';
import ShareModal from './ShareModal'; // Import ShareModal component
import { WandSparkles, ChevronLeft, Trash2, Plus, Pencil} from 'lucide-react';
import '../../Question.css';
import './QuizCreation.css'



const QuizCreation = ({ onCancel, onSave, initialData }) => {
  const [newEvaluationTitle, setNewEvaluationTitle] = useState('');
  const [selectedTheme, setSelectedTheme] = useState('');
  const [selectedChapter, setSelectedChapter] = useState('');
  const [selectedNotion, setSelectedNotion] = useState('');
  const [isCreated, setIsCreated] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isSharing, setIsSharing] = useState(false);
    // warning msg car variable déclarée (selectedEvaluation) et valeur assignée mais jamais utilisée dans le code
  // eslint-disable-next-line no-unused-vars
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState([]);

  useEffect(() => {
    if (initialData) {
      setNewEvaluationTitle(initialData.title);
      setSelectedTheme(initialData.theme);
      setSelectedChapter(initialData.chapter);
      setSelectedNotion(initialData.notion);
      setIsCreated(true);
    }
  }, [initialData]);

  const handleSaveEvaluation = () => {
    const newEvaluation = {
      title: newEvaluationTitle,
      theme: selectedTheme,
      chapter: selectedChapter,
      notion: selectedNotion,
      questions: questions,
    };
    onSave(newEvaluation);
    resetForm();
  };

  const resetForm = () => {
    setNewEvaluationTitle('');
    setSelectedTheme('');
    setSelectedChapter('');
    setSelectedNotion('');
    setIsCreated(false);
    setQuestions([]);
  };

  const addQuestion = () => {
    setQuestions([...questions, { question: '', answers: [{ text: '', isCorrect: false }, { text: '', isCorrect: false }, { text: '', isCorrect: false }, { text: '', isCorrect: false }], isSaved: false }]);
  };

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index][field] = value;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (qIndex, aIndex, field, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answers[aIndex][field] = value;
    setQuestions(newQuestions);
  };

  const deleteQuestion = (index) => {
    const newQuestions = questions.filter((_, qIndex) => qIndex !== index);
    setQuestions(newQuestions);
  };

  const saveQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].isSaved = true;
    setQuestions(newQuestions);
  };

  const editQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].isSaved = false;
    setQuestions(newQuestions);
  };

    // warning msg car variable déclarée (handleShareEvaluation) et valeur assignée mais jamais utilisée dans le code
  // eslint-disable-next-line no-unused-vars
  const handleShareEvaluation = (evaluation) => {
    setSelectedEvaluation(evaluation);
    setIsSharing(true);
  };

  const handleCancelShare = () => {
    setIsSharing(false);
    setSelectedEvaluation(null);
    setSelectedClasses([]);
  };

  const handleValidateShare = () => {
    // Implement the logic to share the evaluation with the selected classes
    console.log('Evaluation shared with classes:', selectedClasses);
    setIsSharing(false);
    setSelectedEvaluation(null);
    setSelectedClasses([]);
  };

  return (
    <div className="mt-8">
      {isCreated ? (
        <>

          <div className="flex justify-start">
              <button
              onClick={onCancel}
              className="button-outline"
              >
              <ChevronLeft size={20} className="mr-1" /> Retour
              </button>
          </div>

        
          <div className='background-primary'>
          <h2>{newEvaluationTitle}</h2>
          
          <div className="flex flex-wrap mb-4">
            {selectedTheme && (
              <span className="notion-tag">
                {selectedTheme}
              </span>
            )}
            {selectedChapter && (
              <span className="notion-tag">
                {selectedChapter}
              </span>
            )}
            {selectedNotion && (
              <span className="notion-tag">
                {selectedNotion}
              </span>
            )}
          </div>

          <p className="text">Evaluation créée ! À toi d'ajouter tes questions !</p>

          <div className="flex space-x-4 mt-4">
            <button className="button-primary">
              <WandSparkles size={20} className="mr-1" /> Générer avec l'IA
            </button>
            <button
              className="button-outline"
              onClick={addQuestion}
            >
              Créer soi-même
            </button>
          </div>

          {/* <div>
            <input
              type="text"
              placeholder="Rechercher des questions"
              className="selector-container"
            />
          </div>
          */}
          </div>

          {questions
              .map((q, qIndex) => ({
                ...q,
                originalIndex: qIndex, // Sauvegarde l'index original pour garder la numérotation constante
              }))
              .sort((a, b) => (a.isSaved === b.isSaved ? 0 : a.isSaved ? 1 : -1)) // Les questions non enregistrées en haut
              .map((q) => (
                
            <div key={q.originalIndex} className={q.isSaved ? 'question-saved' : 'background-secondary'}>
              <div className="absolute top-2 right-2 flex space-x-3">
                {q.isSaved && (
                  <button
                    onClick={() => editQuestion(q.originalIndex)}
                    className="button-icon"
                  >
                    <Pencil size={20} />
                  </button>
                )}
                <button
                  onClick={() => deleteQuestion(q.originalIndex)}
                  className="button-icon"
                >
                  <Trash2 size={20}/>
                </button>
              </div>
              <div className="mb-4">
                <div className='mb-4'>
                  <label className="big-text">
                    Question {q.originalIndex + 1}
                  </label>
                </div>
                {q.isSaved ? (
                  <div>
                    <p className="text">{q.question}</p>
                    {q.answers.map((answer, aIndex) => (
                      <div key={aIndex} className="flex items-center mb-2">
                        <p className={`question-number question-number-${aIndex + 1}`}>{aIndex + 1}</p>
                        <p className={answer.isCorrect ? "correct-box" : "proposition-box"}>
                          {answer.text}
                        </p>

                        {/* {answer.isCorrect && <span className="success-text">Correcte</span>} */}
                      </div>
                    ))}
                    
                    {/* <div>
                    <div className='flex justify-end'> 
                      <button
                        onClick={() => editQuestion(q.originalIndex)}
                        className="button-outline"
                      >
                        Modifier
                      </button>
                    </div>
                    */}

                  </div>
                ) : (
                  <div>
                    <div className='mb-6'>
                    <input
                      type="text"
                      placeholder="Question"
                      className="selector-container"
                      value={q.question}
                      onChange={(e) => handleQuestionChange(q.originalIndex, 'question', e.target.value)}
                    />
                    </div>
                    {q.answers.map((answer, aIndex) => (
                      <div key={aIndex} className="mb-4 flex items-center">
                        
                        <p className={`question-number question-number-${aIndex + 1}`}>{aIndex + 1}</p>

                        <input
                          type="text"
                          placeholder={`Réponse ${aIndex + 1}`}
                          className="selector-container"
                          value={answer.text}
                          onChange={(e) => handleAnswerChange(q.originalIndex, aIndex, 'text', e.target.value)}
                        />
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            className="mr-2"
                            checked={answer.isCorrect}
                            onChange={(e) => handleAnswerChange(q.originalIndex, aIndex, 'isCorrect', e.target.checked)}
                          />
                          Correcte
                        </label>
                      </div>
                    ))}
                    <div className='flex justify-end mt-6'>
                    <button 
                      onClick={() => saveQuestion(q.originalIndex)}
                      className="button-primary"
                    >
                      <Plus size={20} className="mr-1" /> Ajouter
                    </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
  
            <div className="flex justify-start">
              <button
              onClick={onCancel}
              className="button-outline"
              >
              <ChevronLeft size={20} className="mr-1" /> Annuler
              </button>
            </div>
        
          <h2>Créer une nouvelle évaluation</h2>
          <div className='background-primary'>
            <div className="mb-4">
              <h3>Titre de l'évaluation</h3>
              <p className="indication-text">Donne un titre à ta nouvelle évaluation</p>
              <input
                type="text"
                placeholder="Titre"
                className="selector-container"
                value={newEvaluationTitle}
                onChange={(e) => setNewEvaluationTitle(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <h3>Notion évaluée par défaut</h3>
              <p className="text mb-4">
                Indique la notion évaluée dans cette évaluation ! Si tu évalues différentes notions, tu pourras toujours préciser question par question.
              </p>
              <div className="mb-2">
                <label className="indication-text">Indique le thème évalué</label>
                <select
                  className="selector-container"
                  value={selectedTheme}
                  onChange={(e) => setSelectedTheme(e.target.value)}
                >
                  <option value="">Sélectionner un thème</option>
                  {/* Add options here */}
                </select>
              </div>
              <div className="mb-2">
                <label className="indication-text">Indique le chapitre évalué</label>
                <select
                  className="selector-container"
                  value={selectedChapter}
                  onChange={(e) => setSelectedChapter(e.target.value)}
                >
                  <option value="">Sélectionner un chapitre</option>
                  {/* Add options here */}
                </select>
              </div>
              <div className="mb-2">
                <label className="indication-text">Indique la notion évaluée</label>
                <select
                  className="selector-container"
                  value={selectedNotion}
                  onChange={(e) => setSelectedNotion(e.target.value)}
                >
                  <option value="">Sélectionner une notion</option>
                  {/* Add options here */}
                </select>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleSaveEvaluation}
              className="button-primary"
            >
              Enregistrer
            </button>
          </div>
        </>
      )}
      <ShareModal
        isOpen={isSharing}
        onClose={handleCancelShare}
        onValidate={handleValidateShare}
        classes={['Classe 1', 'Classe 2', 'Classe 3']} // Replace with actual classes for the selected level
        selectedClasses={selectedClasses}
        setSelectedClasses={setSelectedClasses}
      />
    </div>
  );
};

export default QuizCreation;