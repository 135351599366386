import React from 'react';
import './ProgressBar.css'

const ProgressBar = ({ label, percentage, color }) => {
  return (
    <div className="mb-4 w-full">
      <div className="flex justify-between mb-1">
        <span className='subtext'>{label}</span>
      </div>
      <div className="progress-bar-container">
        <div
          className={`progress-bar-inside-bar`}
          style={{ 
            width: `${percentage}%`, 
            backgroundColor: color,
          }}
        >
        </div>
        <p className='percentage-text'>{percentage}%</p>
      </div>
    </div>
  );
};

export default ProgressBar;