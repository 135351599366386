import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Profile = () => {
  const [userData, setUserData] = useState(null);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('http://localhost:8000/backapp/' + localStorage.getItem('userType') + '/' + localStorage.getItem('userId') + '/');
      setUserData(response.data); // Store the fetched data in the state
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const { first_name, last_name, school, email, classes } = userData;

  return (
    <div className="p-8">
      <div className='BackgroundProfile'>
        <div className="flex items-center mb-4">
          {userData.userProfilePic ? (
            <img src={userData.userProfilePic} alt="Profile" className="w-24 h-24 rounded-full" />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-300 flex items-center justify-center text-3xl font-bold text-gray-700">
              {first_name[0].toUpperCase()}{last_name[0].toUpperCase()}
            </div>
          )}
          <div className="ml-4">
            <div className="font-bold text-xl">{first_name} {last_name}</div>
            <div className="text-sm text-gray-600">{school}</div>
          </div>
        </div>
        <div className="bg-blue-100 p-4 rounded-lg">
          <div className="mb-2">
            <span className="font-semibold">Email: </span>{localStorage.getItem('email')}
          </div>
          <div className="mb-2">
            <span className="font-semibold">Nom complet: </span>{first_name} {last_name}
          </div>
          <div className="mb-2">
            <span className="font-semibold">Institution: </span>{school}
          </div>
          <div className="mb-2">
            <span className="font-semibold">Classe: </span>
            <ul className="list-disc list-inside">
              {classes.map((classItem, index) => (
                <li key={index}>{classItem.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;