// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eval-en-prep-container{
    margin-top: 2rem;           /* Correspond à mt-8 (8 * 0.25rem = 2rem) */
    padding: 1rem;              /* Correspond à p-4 (4 * 0.25rem = 1rem) */
    background-color: var(--color-background);    /* Correspond à bg-white */
    border-radius: 0.5rem;      /* Correspond à rounded-lg (0.5rem = 8px de rayon) */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Correspond à shadow-md (moyenne ombre) */
  }`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Evaluation/Evaluation.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,YAAY,2CAA2C;IACvE,aAAa,eAAe,0CAA0C;IACtE,yCAAyC,KAAK,0BAA0B;IACxE,qBAAqB,OAAO,oDAAoD;IAChF,wCAAwC,EAAE,2CAA2C;EACvF","sourcesContent":[".eval-en-prep-container{\n    margin-top: 2rem;           /* Correspond à mt-8 (8 * 0.25rem = 2rem) */\n    padding: 1rem;              /* Correspond à p-4 (4 * 0.25rem = 1rem) */\n    background-color: var(--color-background);    /* Correspond à bg-white */\n    border-radius: 0.5rem;      /* Correspond à rounded-lg (0.5rem = 8px de rayon) */\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Correspond à shadow-md (moyenne ombre) */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
