import React, { useState } from 'react';

const SupportPage = () => {
  const [issueType, setIssueType] = useState('');
  const [details, setDetails] = useState('');

  const handleIssueTypeChange = (event) => {
    setIssueType(event.target.value);
  };

  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Issue Type:', issueType);
    console.log('Details:', details);
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-semibold mb-4">Aide et Service Client</h2>
      <p className="mb-4">Partagez les bugs ou recommendations pour notre application.</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Type de problème:</label>
          <select
            value={issueType}
            onChange={handleIssueTypeChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Sélectionnez une option</option>
            <option value="bug">Bug</option>
            <option value="recommendation">Recommendation</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Détails:</label>
          <textarea
            value={details}
            onChange={handleDetailsChange}
            className="w-full p-2 border border-gray-300 rounded"
            rows="5"
            placeholder="Décrivez votre problème ou recommendation ici..."
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded"
        >
          Soumettre
        </button>
      </form>
    </div>
  );
};

export default SupportPage;