import React from 'react';
import { Plus } from 'lucide-react';

const CreateEvaluationButton = ({ onCreate }) => {
  return (
    <button
      className="button-primary"
      onClick={onCreate}
    >
      <Plus size={20} className="mr-2" />
      Créer une nouvelle évaluation
    </button>
  );
};

export default CreateEvaluationButton;