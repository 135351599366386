import React from 'react';
import CourseUpload from './CourseUpload';

const Courses = () => {
  return (
    <div className="p-8">
      <h1>Mes cours</h1>
      <CourseUpload />
    </div>
  );
};

export default Courses;