// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-card{
    padding: 1rem;             /* p-4 : remplissage de 4 unités (1rem) */
    border-radius: 0.375rem;   /* rounded : bordures arrondies */
    cursor: pointer;            /* cursor-pointer : pointeur lors du survol */
    background-color: var(--color-background-secondary);
}

.student-card:hover {
    background-color: var(--color-background);
}`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Classes/Classes.css"],"names":[],"mappings":"AAAA;IACI,aAAa,cAAc,yCAAyC;IACpE,uBAAuB,IAAI,iCAAiC;IAC5D,eAAe,aAAa,6CAA6C;IACzE,mDAAmD;AACvD;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":[".student-card{\n    padding: 1rem;             /* p-4 : remplissage de 4 unités (1rem) */\n    border-radius: 0.375rem;   /* rounded : bordures arrondies */\n    cursor: pointer;            /* cursor-pointer : pointeur lors du survol */\n    background-color: var(--color-background-secondary);\n}\n\n.student-card:hover {\n    background-color: var(--color-background);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
