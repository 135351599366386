import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './StudentDetailsPage.css';
import '../.././Question.css';
import { BACKEND_URL } from '../../../config';
import {FileCheck, Check, Zap, X, CheckCircle, XCircle} from 'lucide-react';
import {NotebookText, ChevronLeft, ChevronRight, CircleDashed, CircleCheck, Minus} from 'lucide-react';
import ProgressBar from '../../ProgressBar';



const getProgressBarColor = (score) => {
  if (score > 60) return 'var(--color-success)';
  if (score > 30) return 'var(--color-warning)';
  return 'var(--color-danger)';
};

const StudentDetailsPage = ({ student, onBack }) => {
  const [view, setView] = useState('notions'); // Default view is "Vue par notions"
  const [notionData, setNotionData] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [openChapters, setOpenChapters] = useState({});
  const [openThemes, setOpenThemes] = useState({});

  const toggleTheme = (theme) => {
    setOpenThemes((prevState) => ({
      ...prevState,
      [theme]: !prevState[theme], // Change l'état de "ouvert" pour ce thème
    }));
  };

  const toggleChapter = (chapterKey) => {
    setOpenChapters((prev) => ({
      ...prev,
      [chapterKey]: !prev[chapterKey], // Inverse l'état du chapitre
    }));
  };
      

  useEffect(() => {
    const fetchNotionData = async () => {
      const teacherId = localStorage.getItem('userId'); // Assuming the teacher ID is stored in localStorage
      const studentId = student.id;
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/teacher/notion-view`, {
          params: {
            teacher_id: teacherId,
            student_id: studentId
          }
        });
        setNotionData(response.data);
      } catch (error) {
        console.error('Error fetching notion data:', error);
      }
    };

    const fetchQuizData = async () => {
      const teacherId = localStorage.getItem('userId'); // Assuming the teacher ID is stored in localStorage
      const studentId = student.id;
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/teacher/student-quizzes`, {
          params: {
            teacher_id: teacherId,
            student_id: studentId
          }
        });
        setQuizData(response.data);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      }
    };

    if (view === 'notions') {
      fetchNotionData();
    } else if (view === 'quizz') {
      fetchQuizData();
    }
  }, [view, student.id]);

  const handleViewChange = (newView) => {
    setView(newView);
    setSelectedQuiz(null); // Reset selected quiz when changing view
  };

  const handleQuizClick = (quizId) => {
    const quiz = quizData[quizId];
    if (quiz) {
      setSelectedQuiz({
        ...quiz,
        id: quizId, // Add the ID to the quiz object
        questions: quiz.questions || [],
        student_answers: quiz.student_answers || {}
      });
    }
  };

  const transformQuizData = (data) => {
    return Object.values(data).map(quiz => ({
      ...quiz,
      student_answers: quiz.student_answers || {}
    }));
  };
  


  if (!student) {
    return <p>Aucune donnée disponible pour cet élève.</p>;
  }

  return (
    <div className="p-8">
      <button onClick={onBack} className="button-clear">
        <ChevronLeft size={16} />
        Retour à mes élèves
      </button>
      
      <div className="student-profile">
        <h2>{`${student.first_name} ${student.last_name}`}</h2>
        <p className='subtext'>{student.email}</p>
        <p>{student.classes[0].name}</p>
      </div>

      <div className="view-selection">
        <button onClick={() => handleViewChange('notions')} className={`button-selector ${view === 'notions' ? 'selected' : ''}`}>
          <NotebookText size={20} />
          Vue par notions
        </button>
        <button onClick={() => handleViewChange('quizz')} className={`button-selector ${view === 'quizz' ? 'selected' : ''}`}>
          <FileCheck size={20} />
          Vue par évaluations
        </button>
      </div>

      {view === 'notions' && notionData && (
        <div className="notion-view">
        {Object.entries(notionData).map(([subject, subjectData]) => (
          <div key={subject}>
            {Object.entries(subjectData).map(([theme, themeData]) => (
              <div key={theme}>
                <div className='theme-box'>
                  <div className='theme-name'>
                    <ChevronRight 
                      onClick={() => toggleTheme(theme)}
                      className={`chevron ${openThemes[theme] ? 'rotated' : ''}`}
                    />
                    <h3>{theme}</h3>
                  </div>
                  <div className="theme-stats">
                    <div className="seen-count">
                      <p className="small-text">
                        {themeData.info_theme.nbr_chapters_seen}/{themeData.info_theme.total_chapters} chapitres vus
                      </p>
                    </div>
                    <div className='progress-bar'>
                      <ProgressBar
                        label="Réussite"
                        percentage={Math.round((themeData.info_theme.average || 0) * 100)}
                        color={getProgressBarColor(themeData.info_theme.average * 100 || 0)}
                      />
                    </div>
                  </div>
                </div>
                
                {openThemes[theme] && (
                  <div>
                    {themeData.chapters.map((chapter) => (
                      <div key={chapter.name} className={`full-chapter-box ${openChapters[chapter.name] ? 'open' : ''}`}>
                        <div className='chapter-box'>
                          <div className='chapter-name'>
                            <ChevronRight 
                              onClick={() => toggleChapter(chapter.name)} 
                              className={`chevron ${openChapters[chapter.name] && 'rotated'}`}
                            />
                            <p className='big-text'>{chapter.name}</p>
                          </div>
                          <div className="chapter-stats">
                            <div className="seen-count">
                              <p className="small-text">
                                {chapter.info_chapter.nbr_notions_seen}/{chapter.info_chapter.total_notions} notions vues
                              </p>
                            </div>
                            <div className='progress-bar'>
                              <ProgressBar
                                label="Réussite"
                                percentage={Math.round((chapter.info_chapter.average || 0) * 100)}
                                color={getProgressBarColor(chapter.info_chapter.average * 100 || 0)}
                              />
                            </div>
                          </div>
                        </div>

                          {openChapters[chapter.name] && (
                            <div>
                              {chapter.notions.map((notion) => (
                                <div className='notion-box' key={notion.id}>
                                  <p className='notion-name small-text'>{notion.name}</p>
                                  <div className='progress-bar'>
                                    <ProgressBar
                                      label="Réussite"
                                      percentage={Math.round((notion.average || 0) * 100)}
                                      color={getProgressBarColor(notion.average * 100 || 0)}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      

      {view === 'quizz' && quizData && !selectedQuiz && (
        <div className="quiz-view">
          
          {Object.keys(quizData).map(quizId => (
            <div key={quizId} className="quiz-item" onClick={() => handleQuizClick(quizId)}>
              <div className='quiz-main-info'>
                <p className='big-text'>{quizData[quizId].name}</p>
                <p className='subtext'>Partagé le {new Date(quizData[quizId].shared_on).toLocaleDateString()}</p>
              </div>
              <div className={`status flex items-center w-[90px] ${quizData[quizId].attempted ? 'completed' : 'not-completed'}`}>
                {quizData[quizId].attempted ? (
                <CircleCheck size={16}/> // Icône pour complété
                ) : (
                <CircleDashed size={16} /> // Icône pour non complété
                )}
                <p className='small-text'>{quizData[quizId].attempted ? 'Complété' : 'En cours'}</p>
              </div>
              <div className='progress-bar-quizz'>
                <ProgressBar
                  label="Réussite"
                  percentage={quizData[quizId].score || 0} // Utiliser la moyenne calculée
                  color={getProgressBarColor(quizData[quizId].score || 0)}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {view === 'quizz' && selectedQuiz && (
        <div className="quiz-details">
          <button 
            onClick={() => setSelectedQuiz(null)}
            className="button-clear"
          >
            <ChevronLeft size={16} />
            Retour aux évaluations
          </button>
          <div className="quiz-header">
            <div className='quiz-header-general-info'>
              <h2>{selectedQuiz.name}</h2>
              <p className='subtext'>Partagé le {new Date(selectedQuiz.shared_on).toLocaleDateString()}</p>
            </div>
            <div className={`status ${selectedQuiz.attempted  ? 'completed' : 'not-completed'}`}>
              {selectedQuiz.attempted  ? (
              <CircleCheck size={16}/> // Icône pour complété
              ) : (
              <CircleDashed size={16} /> // Icône pour non complété
              )}
              <p className='small-text'>{selectedQuiz.attempted  ? 'Complété' : 'Non complété'}</p>
            </div>
            <ProgressBar
              label="Réussite"
              percentage={selectedQuiz.score || 0} // Utiliser la moyenne calculée
              color={getProgressBarColor(selectedQuiz.score || 0)}
            />
          </div>
          {selectedQuiz.questions && selectedQuiz.questions.map((question, questionIndex) => (
            <div key={question.id} className="question-box">
              <h3>{`Question ${questionIndex + 1}`}</h3>
              <p className='question-text'>{question.question}</p>
              <ul>
              {Object.entries(question.answers).map(([key, answerData]) => {
                const studentAnswer = selectedQuiz.student_answers[question.id];
                const isSelected = studentAnswer?.selected_answers?.includes(key);
                const isCorrect = answerData.isTrue;

                const answerClass = isSelected
                  ? isCorrect
                    ? 'answer-success'
                    : 'answer-danger'
                  : isCorrect
                    ? 'answer-neutral'
                    : 'answer-default'

                return (
                  <li key={key} className="flex items-center gap-2 py-1">
                    <div className={`answer-number answer-number-${key.charCodeAt(0) - 64}`}>
                      {key}  {/* Display A, B, C, D directly */}
                    </div>
                    <div className={`flex-1 p-3 rounded-md ${answerClass}`}>
                      <div className="flex justify-between items-center w-full">
                        <span>{answerData.answer}</span>
                        {isSelected && (
                          isCorrect ? (
                            <Check className="h-5 w-5 text-[#73c57b]" />
                          ) : (
                            <X className="h-5 w-5 text-[#ec6963]" />
                          )
                        )}
                      </div>
                    </div>
                    
                  </li>
                );
              })}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StudentDetailsPage;