// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-recap-container {
    margin-bottom: 1.5rem; /* mb-6 : 1.5rem de marge en bas */
    padding: 1rem; /* p-4 : 1rem de padding (16px) */
    border-radius: 0.375rem; /* rounded : bordures arrondies */
    background-color: var(--color-background);
}`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Classes/StudentDetailsPage.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB,EAAE,kCAAkC;IACzD,aAAa,EAAE,iCAAiC;IAChD,uBAAuB,EAAE,iCAAiC;IAC1D,yCAAyC;AAC7C","sourcesContent":[".student-recap-container {\n    margin-bottom: 1.5rem; /* mb-6 : 1.5rem de marge en bas */\n    padding: 1rem; /* p-4 : 1rem de padding (16px) */\n    border-radius: 0.375rem; /* rounded : bordures arrondies */\n    background-color: var(--color-background);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
