
import {ChevronLeft} from 'lucide-react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../config';

const PracticeQuestions = ({ notion, onBackClick }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const userId = localStorage.getItem('userId');
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/student/recommend_questions/${userId}/${notion}/5/`);
        setQuestions(response.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, [notion]);

  const handleAnswerChange = (answerIndex) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const newAnswers = prevSelectedAnswers.includes(answerIndex)
        ? prevSelectedAnswers.filter((index) => index !== answerIndex)
        : [...prevSelectedAnswers, answerIndex];
      return newAnswers;
    });
  };

  const handleNextQuestion = () => {
    setSelectedAnswers([]);
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setSelectedAnswers([]);
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  if (questions.length === 0) {
    return <div>Loading...</div>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div>
      <button onClick={onBackClick} className="button-outline">
        <ChevronLeft size={20} className="mr-1" /> Retour
      </button>
      <h2>{notion}</h2>
      <div className="question-box">
        <p className="question-text">{currentQuestion.question}</p>
        {currentQuestion.answers.map((answer, aIndex) => (
          <div key={aIndex} className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={selectedAnswers.includes(aIndex)}
              onChange={() => handleAnswerChange(aIndex)}
              className="mr-2"
            />
            <p className={`question-number question-number-${aIndex + 1}`}>{aIndex + 1}</p>
            <p className="proposition-box">
              {answer.text}
            </p>
          </div>
        ))}
      </div>
      <div className="flex justify-between">
        <button
          onClick={handlePreviousQuestion}
          disabled={currentQuestionIndex === 0}
          className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
        >
          Précédent
        </button>
        <button
          onClick={handleNextQuestion}
          disabled={currentQuestionIndex === questions.length - 1}
          className="button-primary"
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default PracticeQuestions;