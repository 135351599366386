import type React from "react"
import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { ChevronDown, ChevronRight, Check } from "lucide-react"
import type { Theme } from "../../types/theme"
import type { Chapter } from "../../types/chapter"
import type { Notion } from "../../types/notion"
import { useEffect } from "react"
import './multiselect.css'


interface SmoothSelectorProps {
    themes: Theme[]
    selectedNotions: string[]
    onNotionSelect: (notionIds: string[]) => void
}
  

export function SmoothSelector({ 
    themes, 
    selectedNotions: initialSelectedNotions, 
    onNotionSelect 
}: SmoothSelectorProps) {
  const [expandedThemes, setExpandedThemes] = useState<string[]>([])
  const [expandedChapters, setExpandedChapters] = useState<string[]>([])
  const [selectedNotions, setSelectedNotions] = useState<string[]>(initialSelectedNotions) // Use renamed prop

  // Update selectedNotions when prop changes
  useEffect(() => {
    setSelectedNotions(initialSelectedNotions)
  }, [initialSelectedNotions])

  useEffect(() => {
    if (themes.length) {
      const newExpandedThemes: string[] = [];
      const newExpandedChapters: string[] = [];
      themes.forEach(theme => {
        theme.chapters?.forEach(chapter => {
          if (chapter.notions?.some(notion => selectedNotions.includes(notion.id))) {
            if (!newExpandedThemes.includes(theme.id)) {
              newExpandedThemes.push(theme.id);
            }
            newExpandedChapters.push(chapter.id);
          }
        });
      });
      setExpandedThemes(newExpandedThemes);
      setExpandedChapters(newExpandedChapters);
    }
  }, [themes, selectedNotions]);

  const toggleExpanded = (
    id: string,
    expandedItems: string[],
    setExpandedItems: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    setExpandedItems((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]))
  }

  const toggleNotion = (notionId: string | number) => {
    const idString = notionId.toString();
    const newNotions = selectedNotions.includes(idString)
      ? selectedNotions.filter(id => id !== idString)
      : [...selectedNotions, idString];
    setSelectedNotions(newNotions);
    onNotionSelect(newNotions);
  };

  const isExpanded = (id: string, expandedItems: string[]) => expandedItems.includes(id)

  return (
    <div className="py-6 max-w-2xl mx-auto">
      <div className="space-y-4">
        {themes.map((theme) => (
          <ThemeItem
            key={theme.id}
            theme={theme}
            isExpanded={isExpanded(theme.id, expandedThemes)}
            onToggle={() => toggleExpanded(theme.id, expandedThemes, setExpandedThemes)}
            expandedChapters={expandedChapters}
            setExpandedChapters={setExpandedChapters}
            selectedNotions={selectedNotions}
            toggleNotion={toggleNotion}
            toggleExpanded={toggleExpanded}
          />
        ))}
      </div>
    </div>
  )
}

function ThemeItem({
  theme,
  isExpanded,
  onToggle,
  expandedChapters,
  setExpandedChapters,
  selectedNotions,
  toggleNotion,
  toggleExpanded,
}: {
  theme: Theme
  isExpanded: boolean
  onToggle: () => void
  expandedChapters: string[]
  setExpandedChapters: React.Dispatch<React.SetStateAction<string[]>>
  selectedNotions: string[]
  toggleNotion: (notionId: string) => void
  toggleExpanded: (
    id: string,
    expandedItems: string[],
    setExpandedItems: React.Dispatch<React.SetStateAction<string[]>>,
  ) => void
}) {
  return (
    <div className={`theme-container ${isExpanded ? "expanded" : ""}`}>
      <button
      type="button"
      className={`theme-button ${isExpanded ? "expanded" : ""}`}

        onClick={(e) => {
            e.preventDefault() // Add this
            onToggle()
        }}
      >
        <span>{theme.name}</span>
        {isExpanded ? <ChevronDown className="w-5 h-5" /> : <ChevronRight className="w-5 h-5" />}
      </button>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="px-4 pb-4 space-y-2">
              {theme.chapters?.map((chapter) => (
                <ChapterItem
                  key={chapter.id}
                  chapter={chapter}
                  isExpanded={expandedChapters.includes(chapter.id)}
                  onToggle={() => toggleExpanded(chapter.id, expandedChapters, setExpandedChapters)}
                  selectedNotions={selectedNotions}
                  toggleNotion={toggleNotion}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function ChapterItem({
  chapter,
  isExpanded,
  onToggle,
  selectedNotions,
  toggleNotion,
}: {
  chapter: Chapter
  isExpanded: boolean
  onToggle: () => void
  selectedNotions: string[]
  toggleNotion: (notionId: string) => void
}) {
  return (
    <div className={`chapter-container ${isExpanded ? "expanded" : ""}`}>
      <button
        type="button"
        className={`chapter-button ${isExpanded ? "expanded" : ""}`}
        onClick={(e) => {
            e.preventDefault() // Add this
            onToggle()
        }}
      >
        <span>{chapter.name}</span>
        {isExpanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
      </button>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="px-3 pb-3 space-y-1">
              {chapter.notions.map((notion) => (
                <NotionItem
                  key={notion.id}
                  notion={notion}
                  isSelected={selectedNotions.includes(notion.id)}
                  onToggle={() => toggleNotion(notion.id)}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function NotionItem({
  notion,
  isSelected,
  onToggle,
}: {
  notion: Notion
  isSelected: boolean
  onToggle: () => void
}) {
  return (
    <button
        type="button"
        className={`notion-button ${isSelected ? "selected" : ""}`}
        onClick={(e) => {
            e.preventDefault() 
            onToggle()
        }}
    >
        <span>{notion.name}</span>
        {isSelected && <Check className="w-4 h-4" />}
    </button>
  )
}

