import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './StudentDetailsPage.css';
import { BACKEND_URL } from '../../../config';

const StudentDetailsPage = ({ student, onBack }) => {
  const [view, setView] = useState('notions'); // Default view is "Vue par notions"
  const [notionData, setNotionData] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);

  useEffect(() => {
    const fetchNotionData = async () => {
      const teacherId = localStorage.getItem('userId'); // Assuming the teacher ID is stored in localStorage
      const studentId = student.id;
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/teacher/notion_view/${teacherId}/${studentId}/`);
        setNotionData(response.data);
      } catch (error) {
        console.error('Error fetching notion data:', error);
      }
    };

    const fetchQuizData = async () => {
      const teacherId = localStorage.getItem('userId'); // Assuming the teacher ID is stored in localStorage
      const studentId = student.id;
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/teacher/student_quizzes/${teacherId}/${studentId}/`);
        setQuizData(response.data);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      }
    };

    if (view === 'notions') {
      fetchNotionData();
    } else if (view === 'quizz') {
      fetchQuizData();
    }
  }, [view, student.id]);

  const handleViewChange = (newView) => {
    setView(newView);
    setSelectedQuiz(null); // Reset selected quiz when changing view
  };

  const handleQuizClick = (quizId) => {
    setSelectedQuiz(quizData[quizId]);
  };

  if (!student) {
    return <p>Aucune donnée disponible pour l'élève.</p>;
  }

  return (
    <div className="p-8">
      <div className="student-profile">
        <h2>{`${student.first_name} ${student.last_name}`}</h2>
        <p>{student.email}</p>
        <p>{student.classes[0].name}</p>
      </div>
      <button onClick={onBack} className="button-outline">Retour aux Classes</button>
      <div className="view-selection">
        <button onClick={() => handleViewChange('notions')} className={view === 'notions' ? 'selected' : ''}>
          Vue par notions
        </button>
        <button onClick={() => handleViewChange('quizz')} className={view === 'quizz' ? 'selected' : ''}>
          Vue par quizz
        </button>
      </div>

      {view === 'notions' && notionData && (
        <div className="notion-view">
          {Object.keys(notionData).map(subject => (
            <div key={subject}>
              <h2>{subject}</h2>
              {Object.keys(notionData[subject]).map(theme => (
                <div key={theme}>
                  <h3>{theme}</h3>
                  {Object.keys(notionData[subject][theme]).map(chapter => (
                    <div key={chapter}>
                      <h4>{chapter}</h4>
                      {Object.keys(notionData[subject][theme][chapter]).map(notion => (
                        <div key={notion}>
                          <p>{notion}: {notionData[subject][theme][chapter][notion].average}</p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {view === 'quizz' && quizData && !selectedQuiz && (
        <div className="quiz-view">
          {Object.keys(quizData).map(quizId => (
            <div key={quizId} className="quiz-item" onClick={() => handleQuizClick(quizId)}>
              <p>Nom du quizz: {quizData[quizId].name}</p>
              <p>Enseignant: {quizData[quizId].teacher}</p>
              <p>Date de partage: {new Date(quizData[quizId].shared_on).toLocaleDateString()}</p>
              <p>Score: {quizData[quizId].score}</p>
              <p>Essayé: {quizData[quizId].attempted ? 'Oui' : 'Non'}</p>
            </div>
          ))}
        </div>
      )}

      {view === 'quizz' && selectedQuiz && (
        <div className="quiz-details">
          <h2>{selectedQuiz.name}</h2>
          <p>Enseignant: {selectedQuiz.teacher}</p>
          <p>Date de partage: {new Date(selectedQuiz.shared_on).toLocaleDateString()}</p>
          <p>Score: {selectedQuiz.score}</p>
          <p>Essayé: {selectedQuiz.attempted ? 'Oui' : 'Non'}</p>
          <h3>Questions:</h3>
          {selectedQuiz.questions && selectedQuiz.questions.map(question => (
            <div key={question.id} className="question-item">
              <p>{question.question}</p>
              <ul>
                {Object.keys(question.answers).map(answerKey => (
                  <li key={answerKey}>
                    {answerKey}: {question.answers[answerKey].answer} {question.answers[answerKey].isTrue ? '(Correct)' : ''}
                  </li>
                ))}
              </ul>
              <p>Réponses de l'élève: {selectedQuiz.student_answers[question.id] && selectedQuiz.student_answers[question.id].join(', ')}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StudentDetailsPage;