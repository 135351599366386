import React, { useEffect, useState } from 'react';
import ClassProgress from './ClassProgress';
import axios from 'axios';
import './Profile.css';
import { BACKEND_URL } from '../../../config';
import { Link } from 'react-router-dom';

const Profile = () => {
  const [profileData, setProfileData] = useState({
    userName: '',
    userEmail: '',
    userInstitution: '',
    userClasses: [],
    userSubject: '',
    classes: []
  });

  const getInitials = (name) => {
    const initials = name.split(' ').map((n) => n[0]).join('');
    return initials.toUpperCase();
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/${localStorage.getItem('userType')}/${localStorage.getItem('userId')}/`);
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  const { userName, userEmail, userInstitution, userClasses, userSubject, classes } = profileData;

  return (
    <div className="p-8">
    <div className="BackgroundProfile">
        <div className="avatar-container">
          {getInitials(userName)}
        </div>
        <div>
          <div className='mb-4'>
            <h1 className='mb-0'>{userName}</h1>
            <p className="subtext"><strong></strong> {userEmail}</p>
          </div>
          <p className="big-text"><strong></strong> {userSubject.name}</p>
          <p className="text"><strong></strong> {userInstitution || 'No institution'}</p>
          <p className="notion-label"><strong></strong> {userClasses.join(', ')}</p>
        </div> 
      </div>

      <div>
        <h2 className="text-xl font-bold mb-4">Mes classes</h2>
        {classes.map(classInfo => (
          <Link to={`/classes/`} key={classInfo.id} className="class-link">
            <ClassProgress
              className={classInfo.name} 
              avancement={classInfo.avancement}
              reussite={classInfo.reussite}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Profile;