import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Sidebar from './components/Sidebar';
import StudentRoutes from './components/StudentRoute';
import TeacherRoutes from './components/TeacherRoute';
import Login from './components/Login';

function App() {
  const [userType, setUserType] = useState(null); // State to track user type (null means not logged in)

  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);

  const handleLogin = (type) => {
    setUserType(type);
    localStorage.setItem('userType', type);
    
  };

  const handleLogout = () => {
    localStorage.clear();
  };

  return (
    <Router>
      <div className="flex h-screen bg-gray-100">
        {userType ? (
          <>
            <Sidebar
              userType={userType}
              userName={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')}
              userClassOrInstitution={localStorage.getItem('school_name')}
              userProfilePic=""
              activeItem="profile"
              onLogout={handleLogout}
            />
            <div className="flex-1 overflow-y-auto">
              <Routes>
                {userType === 'student' ? (
                  <Route path="/*" element={<StudentRoutes/>} />
                ) : (
                  <Route path="/*" element={<TeacherRoutes/>} />
                )}
              </Routes>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;