import { Card } from '../components/ui/card'
import { EvaluationCard } from './evaluation-card'
import type { EvaluationSectionProps } from '../types/evaluation'

export function EvaluationSection({ title, evaluations, onShare, onModify, onDelete }: EvaluationSectionProps) {
  return (
    <Card className="mt-8 bg-[#e7f2ff] p-6">
      <h2>{title}</h2>
      <div className="divide-y">
        {evaluations.map((evaluation) => (
          <EvaluationCard
            key={evaluation.id}
            evaluation={evaluation}
            onShare={onShare}
            onModify={onModify}
            onDelete={onDelete}
            showProgress={title === 'Évaluations partagées'}
          />
        ))}
      </div>
    </Card>
  )
}

