import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './CourseUpload.css'


const CourseUpload = () => {
  const [file, setFile] = useState(null);

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('File uploaded successfully');
      } else {
        alert('File upload failed');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };

  return (
    <div>
      <h2>Télécharger un cours</h2>

      <div className='duo-buttons'>

      <input 
          type="file" 
          id="file-upload" 
          accept="application/pdf" 
          onChange={onFileChange} 
          className="hidden" // Cache le bouton natif
        />

        <label 
          htmlFor="file-upload" 
          className="button-primary cursor-pointer inline-block"> {/* Style personnalisé */}
          Choisir un fichier
        </label>


      {file && (
        <div className="mb-4">
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
            <Viewer fileUrl={URL.createObjectURL(file)} />
          </Worker>
        </div>
      )}
      
      <button onClick={handleUpload} className="button-outline">
        Télécharger
      </button>
      </div>

    </div>
  );
};

export default CourseUpload;