import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StudentDetailsPage from './StudentDetailsPage';
import './Classes.css';
import ProgressBar from '../../ProgressBar';
import { BACKEND_URL } from '../../../config';

const Classes = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentsData, setStudentsData] = useState([]);

  useEffect(() => {
    const fetchStudentsData = async () => {
      const teacherId = localStorage.getItem('userId');
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/teacher/students_of_teacher/${teacherId}/`);
        const students = response.data.flatMap(classData => classData.students);
        setStudentsData(students);
      } catch (error) {
        console.error('Error fetching students data:', error);
      }
    };

    fetchStudentsData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStudentClick = (studentId) => {
    const student = studentsData.find(student => student.id === studentId);
    setSelectedStudent(student);
  };

  const handleBackToClasses = () => {
    setSelectedStudent(null);
  };

  const filteredStudents = studentsData.filter(student =>
    `${student.first_name} ${student.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getProgressBarColor = (score) => {
    if (score > 60) return 'var(--color-success)';
    if (score > 30) return 'var(--color-warning)';
    return 'var(--color-danger)';
  };

  const groupedStudents = filteredStudents.reduce((acc, student) => {
    const className = student.classes[0].name;
    if (!acc[className]) {
      acc[className] = [];
    }
    acc[className].push(student);
    return acc;
  }, {});

  return (
    <div className="p-8">
      {selectedStudent ? (
        <StudentDetailsPage student={selectedStudent} onBack={handleBackToClasses} />
      ) : (
        <>
          <h1>Mes élèves</h1>
          <input
            type="text"
            placeholder="Rechercher un élève"
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full p-2 mb-4 border border-gray-300 rounded"
          />
          {Object.keys(groupedStudents).map(className => (
            <div key={className} className="mb-8">
              <h2>{className}</h2>
              {groupedStudents[className].map(student => (
                <div
                  key={student.id}
                  className="student-card"
                  onClick={() => handleStudentClick(student.id)}
                >
                  <div className="flex justify-between items-center mb-2">
                    <span className="big-text">{`${student.first_name} ${student.last_name}`}</span>
                    <span>{student.classes[0].name}</span>
                  </div>
                  <ProgressBar
                    label="Réussite"
                    percentage={student.averageScore || 0}
                    color={getProgressBarColor(student.averageScore || 0)}
                  />
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Classes;