import { useEffect, useState } from "react"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"
import { Pencil, Sparkles, Trash2, ChevronLeft, Edit2 } from 'lucide-react'
import { Button } from "../components/ui/button"
import { Card } from "../components/ui/card"
import { Input } from "../components/ui/input"
import axios from 'axios'
import { BACKEND_URL } from '../../../../config'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import { toast, ToastContainer } from 'react-toastify'


interface Answer {
  answer: string
  isTrue: boolean
}

interface Question {
  id: number
  question: string
  answers: {
    A: Answer
    B: Answer
    C: Answer
    D: Answer
  }
  correct_answers: string[]
  notions: string[]
  themes: string[]
  chapter: string[]
  subject: number | null
}

interface Quiz {
  quiz_id: number
  name: string
  questions: Question[]
  theme: string
  chapter: string
  notion: string
}

interface Theme {
  id: number
  name: string
}

interface Chapter {
  id: number
  name: string
  theme: number
}

interface Notion {
  id: number
  name: string
  chapter: number[]
}

export function GeneratePage() {
  const [searchParams] = useSearchParams()
  const quizz_id = searchParams.get('id')
  const navigate = useNavigate()
  const [quiz, setQuiz] = useState<Quiz | null>(null)
  const [themes, setThemes] = useState<Theme[]>([])
  const [chaptersMap, setChaptersMap] = useState<Record<number, Chapter[]>>({})
  const [notionsMap, setNotionsMap] = useState<Record<number, Notion[]>>({})
  const isNewQuiz = searchParams.get('new') === 'true'
  const isAiGenerated = searchParams.get('aiGenerated') === 'true';
  const [isEditingName, setIsEditingName] = useState(false);
  const [quizName, setQuizName] = useState("");

  useEffect(() => {
    if (quiz?.name) {
      setQuizName(quiz.name);
    }
  }, [quiz?.name]);

  const handleNameChange = (newName: string) => {
    setQuizName(newName);
    setQuiz(prev => prev ? {
      ...prev,
      name: newName
    } : null);
  };

  useEffect(() => {
    if (isAiGenerated) {
      toast.success("Questions générées avec succès par l'IA", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [isAiGenerated]);

  useEffect(() => {
    if (isNewQuiz) {
      toast.success("Évaluation créée avec succès", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [isNewQuiz]);

  useEffect(() => {
    if (quizz_id) {
      fetchQuizData();
    } else {
      // Initialize empty quiz if no quizz_id
      setQuiz({
        quiz_id: 0,
        name: "",
        questions: [],
        theme: "",
        chapter: "",
        notion: ""
      });
    }

  }, [quizz_id]);


  useEffect(() => {
    fetchThemes()
  }, [])

  useEffect(() => {
    if (quiz?.questions) {
      quiz.questions.forEach(question => {
        if (question.themes[0]) {
          fetchChapters(question.themes[0].toString(), question.id);
        }
        if (question.chapter[0]) {
          fetchNotions(question.chapter[0].toString(), question.id);
        }
      });
    }
  }, [quiz]);

  const fetchThemes = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          quizz_id: quizz_id
        },
      })
      setThemes(response.data.themes)
    } catch (error) {
      console.error('Error fetching themes:', error)
    }
  }

  const fetchChapters = async (themeId: string, questionId: number) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes/chapters`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          theme_id: themeId,
        },
      })
      setChaptersMap(prev => ({
        ...prev,
        [questionId]: response.data.chapters
      }))
    } catch (error) {
      console.error('Error fetching chapters:', error)
    }
  }

  const fetchNotions = async (chapterId: string, questionId: number) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/chapters/notions`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          chapter_id: chapterId,
        },
      })
      setNotionsMap(prev => ({
        ...prev,
        [questionId]: response.data.notions
      }))
    } catch (error) {
      console.error('Error fetching notions:', error)
    }
  }

  const handleThemeChange = (questionId: number, themeId: string) => {
    handleUpdateQuestion(questionId, {
      themes: [themeId], // Keep as string
      chapter: [], 
      notions: [], 
    });
    fetchChapters(themeId, questionId);
  };
  
  const handleChapterChange = (questionId: number, chapterId: string) => {
    handleUpdateQuestion(questionId, {
      chapter: [chapterId], // Keep as string
      notions: [], 
    });
    fetchNotions(chapterId, questionId);
  };
  
  const handleNotionChange = (questionId: number, notionId: string) => {
    handleUpdateQuestion(questionId, {
      notions: [notionId], // Keep as string
    });
  };



  const fetchQuizData = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/retrieve_quiz_in_preperation`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          quiz_id: quizz_id
        }
      })
      console.log('Quiz fetched:', response.data)
      setQuiz(response.data)
    } catch (error) {
      console.error('Error fetching quiz:', error)
    }
  }

  const handleGenerateWithAI = () => {
    if (!quiz) return;
    navigate(`/evaluation/ai/${quiz.quiz_id}`, {
      state: {
        name: quiz.name,
        theme: quiz.theme,
        chapter: quiz.chapter,
        notion: quiz.notion,
        quiz_id: quiz.quiz_id
      }
    });
  };

  const handleCreateManually = () => {
    setQuiz(prev => {
      if (!prev) return prev;
      const tempId = prev.questions.length > 0 
      ? Math.max(...prev.questions.map(q => q.id)) + 1 
      : 1;

      return {
        ...prev,
        questions: [
          ...(prev.questions || []),
          {
            id: tempId,
            newQuestion: true,
            question: "",
            answers: {
              A: { answer: "", isTrue: false },
              B: { answer: "", isTrue: false },
              C: { answer: "", isTrue: false },
              D: { answer: "", isTrue: false }
            },
            correct_answers: [],
            notions: [],
            themes: [],
            chapter: [],
            subject: null 
          }
        ]
      };
    });
  };

  const handleUpdateQuestion = (questionId: number, updates: Partial<Question>) => {
    setQuiz((prev: Quiz | null) => {
      if (!prev) return prev;
      return {
        ...prev,
        questions: prev.questions.map((q: Question) =>
          q.id === questionId ? { ...q, ...updates } : q
        ),
      };
    });
  };
  
  const handleDeleteQuestion = (questionId: number | null) => {
    setQuiz((prev: Quiz | null) => {
      if (!prev) return prev;
      return {
        ...prev,
        questions: prev.questions.filter((q) => q.id !== questionId),
      };
    });
  };
  
  const handleSave = async () => {
    try {
      console.log('Saving quiz:', quiz);
      if (!quiz) return;
      const response = await axios.post(
        `${BACKEND_URL}/backapp/teacher/save_quiz_in_preperation`,
        {
          ...quiz,
          teacher_id: localStorage.getItem('userId'),
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        console.log('Quiz saved successfully');
        navigate('/evaluations', { state: { showSaveSuccess: true } });
      } else {
        toast.error("Erreur lors de l'enregistrement de l'évaluation", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.error('Error saving quiz:', response.statusText);
      }
    } catch (error) {
      toast.error("Erreur lors de l'enregistrement de l'évaluation", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error('Error saving quiz:', error);
    }
  };

  return (
    <div className="container mx-auto py-8 px-8">
      <button className="button-clear" onClick={() => navigate(-1)}>
       <ChevronLeft size={16} />
        Retour
      </button>
      <ToastContainer />
      <Card className="background-primary">
        <div>
          <div className="flex flex-wrap justify-between items-start">
            <div>
              {isEditingName ? (
                <Input
                  value={quizName}
                  onChange={(e) => handleNameChange(e.target.value)}
                  onBlur={() => setIsEditingName(false)}
                  className="text mb-0" // Use your standard name class
                  autoFocus
                />
              ) : (
                <div className="flex items-center gap-2">
                  <h1 className="mb-0"> {/* Use your standard name class */}
                    {quiz?.name || "Nouvelle évaluation"}
                  </h1>
                  <button
                    onClick={() => setIsEditingName(true)}
                    className="button-icon"
                    title="Modifier le nom"
                  >
                    <Edit2 size={12} />
                  </button>
                </div>
              )}
              <p className="subtext">
                {new Date().toLocaleDateString()}
              </p>
            </div>          
            {(quiz?.questions?.length || 0) > 0 && (
              <p className="big-text my-4">{quiz?.questions?.length} questions</p>
            )}
          </div>
          <div className="flex flex-wrap gap-2 mt-4">
            <div>
              <p className="subtext">Thème</p>
              <span className="notion-label">
                {quiz?.theme || "Non défini"}
              </span>
            </div>
            <div>
              <p className="subtext">Chapitre</p>
              <span className="notion-label">
                {quiz?.chapter || "Non défini"}
              </span>
            </div>
            <div>
              <p className="subtext">Notion</p>
              <span className="notion-label">
                {quiz?.notion || "Non défini"}
              </span>
            </div>
          </div>
        </div>
      </Card>
  
      <div className="background-dashed mb-8 m-4">
        <h3>Ajout de questions</h3>
        <p className="small-text mb-4">
          {quizz_id ? "" : "Évaluation créée ! À toi de la remplir avec tes questions !"}
        </p>
        <div className="flex flex-wrap gap-4">
          <Button
            onClick={handleGenerateWithAI}
            className="button-primary"
          >
            <Sparkles size={16} />
            Générer avec l'IA
          </Button>
          <Button
            variant="outline"
            onClick={handleCreateManually}
            className="button-outline"
          >
            <Pencil size={16} />
            Créer soi-même
          </Button>
        </div>
      </div>
  
      <div className="space-y-6">
        {quiz?.questions?.map((question, index) => (
          <Card key={question.id} className="p-6">
            <div className="flex justify-between items-start">
              <h3> Question {index + 1} </h3>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleDeleteQuestion(question.id)}
                  className="button-icon-danger"
                >
                  <Trash2 size={16} />
                </Button>
              </div>
            </div>
  
            <div className="space-y-8">

              {/* Theme/Chapter/Notion selectors */}
              <div className="flex flex-col sm:flex-row flex-wrap gap-4">
                <div className="flex-1">
                  <p className="subtext">Thème de la question</p>
                  <Select 
                    value={question.themes[0]?.toString() || quiz.theme}
                    onValueChange={(value) => handleThemeChange(question.id, value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={quiz.theme || "Sélectionner un thème"} />
                    </SelectTrigger>
                    <SelectContent>
                      {themes.map((theme) => (
                        <SelectItem key={theme.id} value={theme.id.toString()}>
                          {theme.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex-1">
                  <p className="subtext">Chapitre de la question</p>
                  <Select
                    value={question.chapter[0]?.toString() || quiz.chapter}
                    onValueChange={(value) => handleChapterChange(question.id, value)}
                  >
                    <SelectTrigger className="w-full max-w-sm">
                      <SelectValue placeholder={quiz.chapter || "Sélectionner un chapitre"} />
                    </SelectTrigger>
                    <SelectContent>
                      {chaptersMap[question.id]?.map((chapter) => (
                        <SelectItem key={chapter.id} value={chapter.id.toString()}>
                          {chapter.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex-1">
                  <p className="subtext">Notion de la question</p>
                  <Select
                    value={question.notions[0]?.toString() || quiz.notion}
                    onValueChange={(value) => handleNotionChange(question.id, value)}
                  >
                    <SelectTrigger className="w-full max-w-sm">
                      <SelectValue placeholder={quiz.notion || "Sélectionner une notion"} />
                    </SelectTrigger>
                    <SelectContent>
                      {notionsMap[question.id]?.map((notion) => (
                        <SelectItem key={notion.id} value={notion.id.toString()}>
                          {notion.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div>
                <p className="subtext">Intitulé de la question</p>
                <Input
                  value={question.question}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { question: e.target.value })
                  }
                  placeholder="Intitulé de la question"
                  className="font-medium"
                />
              </div>

              <div className="space-y-4">
                {Object.entries(question.answers).map(([key, answer]) => (
                  <div key={key} className="flex items-center gap-2">
                    <div className={`answer-number ${
                      key === 'A' ? "answer-number-1" :
                      key === 'B' ? "answer-number-2" :
                      key === 'C' ? "answer-number-3" :
                      "answer-number-4"
                    }`}>
                      {key}
                    </div>
                    <div className={`flex-1 ${answer.isTrue ? "bg-[#d4edd6]" : "bg-[#f3f9ff]"} rounded-md`}>
                      <Input
                        value={answer.answer}
                        onChange={(e) =>
                          handleUpdateQuestion(question.id, {
                            answers: {
                              ...question.answers,
                              [key]: { ...answer, answer: e.target.value }
                            }
                          })
                        }
                        placeholder={`Réponse ${key}`}
                        className="border-0 bg-transparent"
                        />
                    </div>
                    <input
                      type="checkbox"
                      checked={answer.isTrue}
                      onChange={(e) =>
                        handleUpdateQuestion(question.id, {
                          answers: {
                            ...question.answers,
                            [key]: { ...answer, isTrue: e.target.checked }
                          }
                        })
                      }
                      className="w-5 h-5 rounded border-gray-300"
                      />
                  </div>
                ))}
              </div>
            </div>
          </Card>
        ))}
      </div>
  
      {(quiz?.questions?.length || 0) > 0 && (
        <div className="mt-8 flex justify-end centered">
          <Button
            onClick={handleSave}
            className="button-primary"
          >
            Enregistrer
          </Button>
        </div>
      )}
    </div>
  );
}