import { useState, useEffect } from "react"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import {ChevronLeft} from 'lucide-react';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import type { EvaluationFormData } from "../types/evaluation"
import { BACKEND_URL } from '../../../../config'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

interface Theme {
  id: number;
  name: string;
}

interface Chapter {
  id: number;
  name: string;
  theme: number;
}
interface Notion {
  id: number;
  name: string;
  chapter: number[];
}


export function EvaluationSetup() {
  const [searchParams] = useSearchParams()
  const selectedYear = searchParams.get('year_id')
  const navigate = useNavigate();
  const [themes, setThemes] = useState<Theme[]>([]);
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [notions, setNotions] = useState<Notion[]>([]);
  const [formData, setFormData] = useState<EvaluationFormData>({
    title: "",
    theme: "",
    chapter: "",
    notion: "",
  })

  useEffect(() => {
    fetchThemes();
  }, []);

  const isFormValid = () => {
    return formData.title.trim() !== "";
  };

  const fetchThemes = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          year_id : selectedYear,
        },
      });
      setThemes(response.data.themes);
    } catch (error) {
      console.error('Error fetching themes:', error);
    }
  };

  const fetchChapters = async (themeId: string) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes/chapters`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          theme_id: themeId,
        },
      });
      setChapters(response.data.chapters);
      console.log('Chapters fetched:', response.data);
    } catch (error) {
      console.error('Error fetching chapters:', error);
    }
  };

  const fetchNotions = async (chapterId: string) => {
    try {
      console.log('Fetching notions for chapter:', chapterId);
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/chapters/notions`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          chapter_id: chapterId,
        },
      });
      console.log('Notions received:', response.data);
      setNotions(response.data.notions);
    } catch (error) {
      console.error('Error fetching notions:', error);
    }
  };

  const handleThemeChange = (value: string) => {
    console.log('Theme changed:', value);
    setFormData({ ...formData, theme: value, chapter: "" }); // Reset chapter
    setChapters([]); // Clear chapters
    setNotions([]);
    if (value) {
      fetchChapters(value);
    }
  };

  const handleChapterChange = (value: string) => {
    console.log('Chapter changed:', value);
    setFormData({ ...formData, chapter: value, notion: "" }); // Reset notion
    setNotions([]); // Clear notions
    if (value) {
      fetchNotions(value);
    }
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    const evaluationData = {
      teacher_id: localStorage.getItem('userId'),
      name: formData.title,
      subject_id: localStorage.getItem('subjectId'), // Assuming this is stored in localStorage
      theme_id: parseInt(formData.theme),
      chapter_id: parseInt(formData.chapter),
      notion_id: parseInt(formData.notion),
      year: selectedYear // Make sure this is available in component state
    };
  
    console.log('Sending evaluation data:', evaluationData);
  
    try {
      const response = await axios.post(
        `${BACKEND_URL}/backapp/teacher/create_evaluation`,
        evaluationData
      );
  
      if (response.status === 201) {
        const quizId = response.data.quiz_id;
        navigate(`/evaluation/generate?id=${quizId}&new=true`);
      }
    } catch (error) {
      console.error('Error creating evaluation:', error);
      toast.error("Erreur lors de la création de l'évaluation", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="p-8">
      <button className="button-clear"
      onClick={() => navigate(-1)}>
        <ChevronLeft size={16} />
        Retour
      </button>

    <div className="background-secondary container max-w-2xl mx-auto mt-8">
      <ToastContainer />
      <h2>
        Titre de l'évaluation
      </h2>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div>
          <label className="subtext">
            Donne un titre à ta nouvelle évaluation
          </label>
          <Input
            placeholder="Bilan - probabilité"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            required
            />
        </div>

        <div className="space-y-4">
          <div className="relative border-2 border-dashed p-6 rounded-lg">
            <span className="absolute -top-3 left-4 px-2 bg-white text-sm text-gray-500">
              Optionnel
            </span>
            <h2>
              Notion évaluée par défaut
            </h2>
            <p className="small-text">
              Indique la notion évaluée dans cette évaluation !
              <br />
              Si tu évalues différentes notions, tu pourras toujours préciser
              question par question 😊
            </p>

            <div className="space-y-2">
              <div>
                <label className="subtext">
                  Indique le thème évalué
                </label>
                <Select 
                  value={formData.theme} 
                  onValueChange={handleThemeChange}
                  >
                  <SelectTrigger>
                    <SelectValue placeholder="Sélectionner un thème" />
                  </SelectTrigger>
                  <SelectContent>
                    {themes.map((theme) => (
                      <SelectItem key={theme.id} value={theme.id.toString()}>
                        {theme.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <label className="subtext">
                  Indique le chapitre évalué
                </label>
                <Select
                  value={formData.chapter}
                  onValueChange={handleChapterChange}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Sélectionner un chapitre" />
                  </SelectTrigger>
                  <SelectContent>
                    {chapters.map((chapter) => (
                      <SelectItem key={chapter.id} value={chapter.id.toString()}>
                        {chapter.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <label className="subtext">
                  Indique la notion évaluée
                </label>
                <Select
                  value={formData.notion}
                  onValueChange={(value) => setFormData({ ...formData, notion: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Sélectionner une notion" />
                  </SelectTrigger>
                  <SelectContent>
                    {notions.map((notion) => (
                      <SelectItem key={notion.id} value={notion.id.toString()}>
                        {notion.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
        
        <div className="centered">

        <Button
          type="submit"
          className="button-primary"
          disabled={!isFormValid()}
          >
          Enregistrer
        </Button>
        </div>
      </form>
    </div>
    </div>
  )
}

