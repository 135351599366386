import { useState } from 'react'
import { X } from 'lucide-react'
import { Button } from '../components/ui/button'
import { Checkbox } from '../components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../components/ui/dialog'

interface Class {
  id: string
  name: string
}

interface ClassSelectionModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: (selectedClasses: string[]) => void
  classes: Class[]
}

export function ClassSelectionModal({
  isOpen,
  onClose,
  onConfirm,
  classes,
}: ClassSelectionModalProps) {
  const [selectedClasses, setSelectedClasses] = useState<string[]>([])

  const handleToggleClass = (classId: string) => {
    setSelectedClasses((prev) =>
      prev.includes(classId)
        ? prev.filter((id) => id !== classId)
        : [...prev, classId]
    )
  }

  const handleConfirm = () => {
    onConfirm(selectedClasses)
    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="">Sélectionner les classes</DialogTitle>
        </DialogHeader>
        <div>
          <div className="space-y-4 max-h-[300px] overflow-y-auto">
            {classes.map((cls) => (
              <div key={cls.id} className="flex items-center space-x-2">
                <Checkbox
                  id={cls.id}
                  checked={selectedClasses.includes(cls.id)}
                  onCheckedChange={() => handleToggleClass(cls.id)}
                />
                <label
                  htmlFor={cls.id}
                  className="small-text leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {cls.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <DialogFooter className="sm:justify-center mt-4">
          {/*<Button
            type="button"
            variant="secondary"
            onClick={onClose}
            className="bg-[#e7f2ff] text-[#1550a7] hover:bg-[#e7f2ff]/80"
          >
            Annuler
          </Button>*/}
          <Button
            type="button"
            onClick={handleConfirm}
            className="button-primary"
          >
            Partager
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

