export const USER_TYPE = process.env.REACT_APP_USER_TYPE;
export const POSTMAN_URL_STUDENT = process.env.REACT_APP_POSTMAN_URL_STUDENT;
export const POSTMAN_URL_TEACHER = process.env.REACT_APP_POSTMAN_URL_TEACHER;
export const LOCAL_DEV = process.env.REACT_APP_LOCAL_DEV;
import axios from 'axios';

let backendUrl = process.env.REACT_APP_BACKEND_URL;

if (LOCAL_DEV === 'true') {
    if (USER_TYPE === 'student') {
        backendUrl = POSTMAN_URL_STUDENT;
    } else if (USER_TYPE === 'teacher') {
        backendUrl = POSTMAN_URL_TEACHER;
    }
} else {
    // Set up axios interceptor for JWT
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Add response interceptor to handle token refresh
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401 && 
                !originalRequest._retry && 
                !originalRequest.url?.includes('/token/refresh/')) {
                
                originalRequest._retry = true;
    
                try {
                    const refreshToken = localStorage.getItem('refreshToken');
                    const response = await axios.post(`${backendUrl}/token/refresh/`, {
                        refresh: refreshToken
                    });
    
                    if (response.data.access) {
                        localStorage.setItem('token', response.data.access);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
                        return axios(originalRequest);
                    }
                } catch (refreshError) {
                    // If refresh failed, clear everything and redirect to login
                    localStorage.clear();
                    window.location.href = '/login';
                    return Promise.reject(refreshError);
                }
            }
            return Promise.reject(error);
        }
    );
}

export const BACKEND_URL = backendUrl;