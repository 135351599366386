import React from 'react';
import ProgressBar from '../../ProgressBar';
import './StudentDetailsPage.css';


const StudentDetailsPage = ({ student, onBack }) => {
  if (!student) {
    return <p>Aucune donnée disponible pour l'élève.</p>;
  }

  const getProgressBarColor = (score) => {
    if (score > 60) return 'var(--color-success)';
    if (score > 30) return 'var(--color-warning)';
    return 'var(--color-danger)';
  };

  return (
    <div className="p-8">
      <button onClick={onBack} className="button-outline">Retour aux Classes</button>

      {/* Résumé Général */}
    
      <div className="student-recap-container">
        
        <div className="flex items-center mb-4">

          <div className="w-24 h-24 rounded-full bg-gray-300 flex items-center justify-center text-3xl font-bold text-gray-700">
            {student.name.split(' ').map((n) => n[0]).join('').toUpperCase()}
          </div>
          <div className='ml-4'>
            <p className='name'>{student.name}</p>
            <p className="text"> {student.className}</p>
            <p className="subtext"> {student.level}</p>
          </div>
        </div>

        <ProgressBar 
          label="Score moyen"  // Ajout du label "Score moyen"
          percentage={student.averageScore}  // Le pourcentage est basé sur le score moyen de l'étudiant
          color={getProgressBarColor(student.averageScore)}  // La couleur est définie par la fonction getProgressBarColor
        />
      </div>

      {/* Notes des Quiz */}
      
      <div className="mb-6 p-4">

        <h3>Notes des Quiz</h3>
        {student.quizzes.map((quiz, index) => (
          <div key={index} className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <div>
                <p className='big-text'>{quiz.name}</p>
                <p className='subtext'>Fait le XX</p> {/* À compléter avec des données */} 
              </div>
              <span>{quiz.score}%</span>
              
            </div>

            <ProgressBar 
            label="Réussite au quizz"  // Ajout du label "Score moyen"
            percentage={quiz.score}  // Le pourcentage est basé sur le score moyen de l'étudiant
            color={getProgressBarColor(quiz.score)}  // La couleur est définie par la fonction getProgressBarColor
            />

          </div>
        ))}
      </div>

      {/* Tests en Attente */}
      <div className="mb-6 p-4">
        <h3>Tests en Attente</h3>
        {student.pendingTests.length > 0 ? (
          <ul className="list-disc list-inside">
            {student.pendingTests.map((test, index) => (
              <li key={index}>{test}</li>
            ))}
          </ul>
        ) : (
          <p>Aucun test en attente.</p>
        )}
      </div>

      {/* Notions */}
      <div className="p-4">
        <h3>Notions</h3>
        <div className="mb-4">
          <h4 className="font-semibold">Bonnes Notes</h4>
          <ul className="list-disc list-inside">
            {student.goodNotions.map((notion, index) => (
              <li key={index}>{notion}</li>
            ))}
          </ul>
        </div>
        <div>
          <h4 className="font-semibold">Mauvaises Notes</h4>
          <ul className="list-disc list-inside">
            {student.poorNotions.map((notion, index) => (
              <li key={index}>{notion}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StudentDetailsPage;