import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserCircle, StickyNote, HelpCircle, LogOut, FolderClosed, BookOpen } from 'lucide-react';
import logo from '../assets/logo.svg';
import LogoutModal from './Logout'; 
import './Sidebar.css'

const Sidebar = ({ userType, userName, userProfilePic, onLogout, userClassOrInstitution}) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [activeItem, setActiveItem] = useState('profile');
  const navigate = useNavigate();

  const menuItems = userType === 'student'
    ? [
        { key: 'profile', icon: <UserCircle size={20} />, label: 'Mon compte', path: '/profile' },
        { key: 'evaluations', icon: <StickyNote size={20} />, label: 'Mes évaluations', path: '/evaluations' },
      ]
    : [
        { key: 'profile', icon: <UserCircle size={20} />, label: 'Mon compte', path: '/profile' },
        { key: 'evaluations', icon: <StickyNote size={20} />, label: 'Mes évaluations', path: '/evaluations' },
        { key: 'classes', icon: <BookOpen size={20} />, label: 'Mes élèves', path: '/classes' },
      ];

  const getInitials = (name) => {
    const initials = name.split(' ').map((n) => n[0]).join('');
    return initials.toUpperCase();
  };

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setShowLogoutModal(true);
  };

  const handleConfirmLogout = () => {
    localStorage.removeItem('userType');
    onLogout();
    setShowLogoutModal(false);
    navigate('/login');
    
  };

  const handleCancelLogout = () => {
    setShowLogoutModal(false);
  };

  const handleMenuItemClick = (path) => {
    navigate(path); // Navigate to the specified path
  };

  return (
    <>
    <div className="sidebar">
      <div>
        <div className="p-4 h-16 flex items-center justify-center bg-white">
          <img src={logo} alt="Company Logo" className="h-12" /> {/* Use the imported SVG */}
        </div>
        <div className="p-4 flex items-center">
          {userProfilePic ? (
            <img src={userProfilePic} alt="Profile" className="w-12 h-12 rounded-full" />
          ) : (
            <div className="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center text-xl font-bold text-gray-700">
              {getInitials(userName)}
            </div>
          )}
          <div className="ml-4">
            <p className='name'>{userName}</p>
            <div className="text">Terminale XX</div>
            <div className="subtext">{userClassOrInstitution}</div>
          </div>
        </div>
        <nav>
          {menuItems.map((item) => (
            <div
              key={item.key}
              className="flex items-center p-4 hover:bg-gray-200 text-gray-800 cursor-pointer"
              onClick={() => handleMenuItemClick(item.path)}
            >
              {item.icon}
              <span className="ml-4">{item.label}</span>
            </div>
          ))}
          <div
            className="flex items-center p-4 hover:bg-gray-200 text-gray-800 cursor-pointer"
            onClick={() => handleMenuItemClick('/help')}
          >
            <HelpCircle size={20} />
            <span className="ml-4">Aide et service client</span>
          </div>
        </nav>
      </div>
      <div className="p-4">
        <div className="flex items-center p-4 hover:bg-gray-200 text-gray-800 cursor-pointer" onClick={handleLogoutClick}>
          <LogOut size={20} />
          <span className="ml-4">Se déconnecter</span>
        </div>
      </div>
      {showLogoutModal && (
        <LogoutModal
          show={showLogoutModal}
          onConfirm={handleConfirmLogout}
          onCancel={handleCancelLogout}
        />
      )}
    </div>


    {/* Bottom Navbar for smaller screens */}
    <div className="mobile-navbar">
    {menuItems.map((item) => (
      <button
        key={item.key}
        onClick={() => setActiveItem(item.key)}
        className={`flex flex-col items-center ${activeItem === item.key ? 'text-blue-500' : 'text-gray-800'}`}
      >
        {item.icon}
        <span className="text-xs">{item.label}</span>
      </button>
    ))}
  </div>
  </>
  );
};

export default Sidebar;