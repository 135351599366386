// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-text{
    color: var(--color-subtext);
    font-style: italic;
    margin-bottom: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Courses/CourseUpload.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".upload-text{\n    color: var(--color-subtext);\n    font-style: italic;\n    margin-bottom: 4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
