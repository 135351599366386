export const USER_TYPE = process.env.REACT_APP_USER_TYPE;
export const POSTMAN_URL_STUDENT = process.env.REACT_APP_POSTMAN_URL_STUDENT;
export const POSTMAN_URL_TEACHER = process.env.REACT_APP_POSTMAN_URL_TEACHER;
export const LOCAL_DEV = process.env.REACT_APP_LOCAL_DEV;

let backendUrl = process.env.REACT_APP_BACKEND_URL;

if (LOCAL_DEV === 'true') {
    if (USER_TYPE === 'student') {
        backendUrl = POSTMAN_URL_STUDENT;
    } else if (USER_TYPE === 'teacher') {
        backendUrl = POSTMAN_URL_TEACHER;
    }
}

export const BACKEND_URL = backendUrl;
console.log('Backend URL:', BACKEND_URL);