import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Profile from './Teacher/Profile/Profile';
import Evaluation from './Teacher/Evaluation/Evaluation';
import Classes from './Teacher/Classes/Classes';
import SupportPage from './Support/SupportPage';
import Courses from './Teacher/Courses/Courses';

const TeacherRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Profile />} />
      <Route path="/help" element={<SupportPage />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/evaluations" element={<Evaluation />} />
      <Route path="/classes" element={<Classes />} />
      <Route path="/courses" element={<Courses />} />
    </Routes>
  );
};

export default TeacherRoutes;