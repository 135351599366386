import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../config';


const EvaluationDetails = ({ studentId, quizId, onBackClick }) => {
  const [quizData, setQuizData] = useState(null);

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/student/previous_quiz/${studentId}/${quizId}/`);
        setQuizData(response.data);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      }
    };

    fetchQuizData();
  }, [studentId, quizId]);

  if (!quizData) {
    return <div>Loading...</div>;
  }

  const { questions, answers } = quizData;

  return (
    <div>
      <button onClick={onBackClick} className="mb-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
        Retour
      </button>
      <h2 className="text-xl font-semibold mb-4">Quiz {quizId}</h2>
      {questions.map((question, qIndex) => (
        <div key={qIndex} className="mb-4 p-4 border border-gray-300 rounded">
          <p className="font-semibold mb-2">{question.question}</p>
          {Object.entries(question.answers).map(([key, answer], aIndex) => (
            <div key={aIndex} className="flex items-center mb-2">
              <p className={`w-full p-2 border ${question.correct_answers.includes(aIndex) ? 'border-green-500' : 'border-gray-300'} rounded mr-2`}>
                {answer.answer}
              </p>
              {question.correct_answers.includes(aIndex) && <span className="text-green-500 font-semibold">Correcte</span>}
              {answers[qIndex] && answers[qIndex].includes(aIndex) && <span className="text-blue-500 font-semibold ml-2">Votre réponse</span>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default EvaluationDetails;