import React from 'react';
import ClassProgress from './ClassProgress';
import '../../Background.css'

const Profile = ({ userName, userEmail, userInstitution, userClasses, userSubject, classesByLevel }) => {
  return (
    <div className="p-8">
      <div className="background-primary">
        <div className="flex items-center mb-4">

          <div className="w-24 h-24 rounded-full bg-gray-300 flex items-center justify-center text-3xl font-bold text-gray-700">
            {userName.split(' ').map((n) => n[0]).join('').toUpperCase()}
          </div>

          <div className="ml-4">
            <p className="user-name">{userName}</p>
            <p className="big-text">{userSubject}</p>
            <p className="user-institution">{userInstitution}</p>
            <p className="user-email">{userEmail}</p>
          </div>

        </div>
      </div>

      <div>
        <h2>Mes classes</h2>
        {Object.keys(classesByLevel).map(level => (
          <div key={level} className="mb-6">
            <h3>{level}</h3>
            {classesByLevel[level].map(classInfo => (
              <ClassProgress
                key={classInfo.name}
                className={classInfo.name}
                avancement={classInfo.avancement}
                reussite={classInfo.reussite}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Profile;