import { useState, useMemo, useEffect  } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { Card } from "../../components/ui/card"
import { Input } from "../../components/ui/input"
import { Button } from "../../components/ui/button"
import { FileUpload } from "../../components/ui/file-upload"
import { LoadingOverlay } from "../../components/ui/loading-overlay"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select"
import { BACKEND_URL } from "../../../../../config"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {X, Sparkles, ChevronLeft} from 'lucide-react';

interface Theme {
  id: number
  name: string
}

interface Chapter {
  id: number
  name: string
  theme: number
}

interface Notion {
  id: number
  name: string
  chapter: number[]
}

interface QuizData {
  name: string
  theme: string
  chapter: string
  notion: string
  quiz_id: number
}

export default function AIGenerationPage() {
  const {id} = useParams();
  const navigate = useNavigate()
  const location = useLocation()
  const [quizData, setQuizData] = useState<QuizData>(location.state as QuizData)

  const [themes, setThemes] = useState<Theme[]>([])
  const [chapters, setChapters] = useState<Chapter[]>([])
  const [notions, setNotions] = useState<Notion[]>([])

  const handleBack = () => {
    const quizId = quizData.quiz_id;
    navigate(`/evaluation/generate?id=${quizId}&aiGenerated=false`);
  };

  useEffect(() => {
    fetchThemes();
  }, []);

  const fetchThemes = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          quizz_id: id,
        },
      });
      setThemes(response.data.themes);
    } catch (error) {
      console.error('Error fetching themes:', error);
    }
  };

  const fetchChapters = async (themeId: string) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes/chapters`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          theme_id: themeId,
        },
      });
      setChapters(response.data.chapters);
    } catch (error) {
      console.error('Error fetching chapters:', error);
    }
  };

  const fetchNotions = async (chapterId: string) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/chapters/notions`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          chapter_id: chapterId,
        },
      });
      setNotions(response.data.notions);
    } catch (error) {
      console.error('Error fetching notions:', error);
    }
  };

  const handleThemeChange = (value: string) => {
    // Find theme name from ID
    const themeName = themes.find(t => t.id.toString() === value)?.name || "";
    setQuizData(prev => ({ 
      ...prev, 
      theme: themeName,
      chapter: "", // Reset chapter and notion when theme changes
      notion: "" 
    }));
    fetchChapters(value);
  };

  const handleChapterChange = (value: string) => {
    // Find chapter name from ID
    const chapterName = chapters.find(c => c.id.toString() === value)?.name || "";
    setQuizData(prev => ({ 
      ...prev, 
      chapter: chapterName,
      notion: "" // Reset notion when chapter changes
    }));
    fetchNotions(value);
  };

  const [file, setFile] = useState<File | null>(null)
  const [questionCount, setQuestionCount] = useState("5")
  const [additionalInstructions, setAdditionalInstructions] = useState("")
  const [isGenerating, setIsGenerating] = useState(false)

  const pdfPreview = useMemo(() => {
    if (!file) return null;
    
    return (
      <div className="max-w-2xl mx-auto border-2 border-[#e5e9ef] rounded-lg overflow-hidden h-[300px]">
        <div className="relative h-full">
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer fileUrl={URL.createObjectURL(file)} />
          </Worker>
          <button 
            onClick={() => setFile(null)}
            className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-100"
          >
            <X size={16} />
          </button>
        </div>
      </div>
    );
  }, [file]);

  const handleGenerate = async () => {
    setIsGenerating(true);
    try {
      const formData = new FormData();
      formData.append("number_of_questions", questionCount);
      formData.append("notion", quizData.notion);
      formData.append("chapter", quizData.chapter);
      formData.append("theme", quizData.theme);
      formData.append("difficulty_level", "medium");
      formData.append("quiz_id", quizData.quiz_id.toString());
  
      if (file) {
        if (file.size > 10 * 1024 * 1024) {
          toast.error("Le fichier ne doit pas dépasser 10MB");
          return;
        }
        formData.append("file", file);
      }

      if (additionalInstructions) {
        formData.append("instructions", additionalInstructions);
      }

      const response = await axios.post(
        `${BACKEND_URL}/backapp/teacher/generate_questions/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.status === 200) {
        navigate(`/evaluation/generate?id=${quizData.quiz_id}&aiGenerated=true`);
      } else {
        toast.error("Le service de génération de question par IA n'est pas disponible 😅", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

    } catch (error) {
      toast.error("Le service de génération de question par IA n'est pas disponible 😅", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Error generating questions:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleFileSelect = async (file: File) => {
    // Check file type
    if (file.type !== 'application/pdf') {
      toast.error("Veuillez soumettre uniquement des fichiers PDF", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
  
    try {
      // Load PDF.js
      const pdfjsLib = await import('pdfjs-dist');
      pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  
      // Load the PDF file
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
  
      // Check number of pages
      if (pdf.numPages > 2) {
        toast.error("Le document ne doit pas dépasser 2 pages", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
  
      // If all validations pass, set the file
      setFile(file);
    } catch (error) {
      console.error('Error checking PDF:', error);
      toast.error("Erreur lors de la vérification du fichier", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      {isGenerating && <LoadingOverlay />}
      <div className="container mx-auto py-8 px-8">
        <button className="button-clear" onClick={handleBack}>
          <ChevronLeft size={16} />
          Retour
        </button>
        
        <Card className="background-primary">
          <div>
            <div className="flex flex-wrap justify-between items-start">
              <div>
                <h1 className="mb-0">
                  {quizData?.name || "Nouvelle évaluation"}
                </h1>
                <p className="subtext">
                  {new Date().toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        </Card>

        <Card className="background-ai max-w-2xl mx-auto">
          <div className="space-y-8">
            <div className="space-y-4">
              <h2 className="text-center">
                Génération de questions 
              </h2>
              <p className="small-text text-center">
              Tu peux générer le nombre de questions que tu souhaites sur la notion sélectionnée du programme, à partir d'un document ou d'une simple indication !
              </p>
            </div>
            <div className="space-y-4">
              <h3>
                Notion à évaluer
              </h3>
              <div className="space-y-2">
                <p className="small-text">
                  Sélectionne ici la notion du programme que tu souhaites évaluer !
                </p>
                <div>
                  <p className="subtext">Thème</p>
                  <Select 
                    value={themes.find(t => t.name === quizData.theme)?.id.toString() || ""}
                    onValueChange={handleThemeChange}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Sélectionner un thème" />
                    </SelectTrigger>
                    <SelectContent>
                      {themes.map((theme) => (
                        <SelectItem key={theme.id} value={theme.id.toString()}>
                          {theme.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <p className="subtext">Chapitre</p>
                  <Select
                    value={chapters.find(c => c.name === quizData.chapter)?.id.toString() || ""}
                    onValueChange={handleChapterChange}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Sélectionner un chapitre" />
                    </SelectTrigger>
                    <SelectContent>
                      {chapters.map((chapter) => (
                        <SelectItem key={chapter.id} value={chapter.id.toString()}>
                          {chapter.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <p className="subtext">Notion</p>
                  <Select
                    value={notions.find(n => n.name === quizData.notion)?.id.toString() || ""}
                    onValueChange={(value) => {
                      const notionName = notions.find(n => n.id.toString() === value)?.name || "";
                      setQuizData(prev => ({ ...prev, notion: notionName }));
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Sélectionner une notion" />
                    </SelectTrigger>
                    <SelectContent>
                      {notions.map((notion) => (
                        <SelectItem key={notion.id} value={notion.id.toString()}>
                          {notion.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
            
            <div className="space-y-4">
              <h3>
                Générer à partir d'un document
              </h3>
              <p className="subtext">
                Document au format PDF et de 2 pages maximum 
              </p>
              {file ? pdfPreview : (
                <FileUpload
                onFileSelect={handleFileSelect}
                className="max-w-2xl mx-auto"
                />
              )}
            </div>
            
            <div className="space-y-4">
              <h3>
                Indications
              </h3>
              <div className="space-y-2">
              <p className="small-text">
                Si tu génères à partir d'un document, ce champ est optionnel. Sinon, indique ici des précisions sur ce que tu attends comme questions !
              </p>

                <div>
                  <label className="subtext">
                    Indications sur les questions attendues
                  </label>
                  <Input
                    placeholder="J'aimerais des questions faciles à propos de..."
                    value={additionalInstructions}
                    onChange={(e) => setAdditionalInstructions(e.target.value)}
                    />
                </div>
              </div>
                <div>
                  <label className="subtext">
                    Nombre de questions
                  </label>
                  <Select
                    value={questionCount}
                    onValueChange={setQuestionCount}
                    >
                    <SelectTrigger className="w-32">
                      <SelectValue placeholder="1" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="1">1</SelectItem>
                      <SelectItem value="3">3</SelectItem>
                      <SelectItem value="5">5</SelectItem>
                      <SelectItem value="10">10</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
            </div>

              <div className="flex justify-center">
                <Button
                  onClick={handleGenerate}
                  disabled={(!file && !additionalInstructions) || isGenerating}
                  className="button-ai"
                  >
                  <Sparkles size={16} />
                  {isGenerating ? "Génération..." : "Générer"}
                </Button>
              </div>
          </div>
        </Card>
      </div>
    </>
  )
}

