import React, { useState } from 'react';
import StudentDetailsPage from './StudentDetailsPage';
import './Classes.css'
import ProgressBar from '../../ProgressBar';


const studentsData = [
  {
    name: 'Alice',
    level: 'Level 1',
    className: 'Class 1A',
    averageScore: 75,
    quizzes: [
      { name: 'Quiz 1', score: 80 },
      { name: 'Quiz 2', score: 70 },
    ],
    pendingTests: ['Test 1', 'Test 2'],
    goodNotions: ['Algebra', 'Geometry'],
    poorNotions: ['Calculus'],
  },
  {
    name: 'Bob',
    level: 'Level 1',
    className: 'Class 1B',
    averageScore: 45,
    quizzes: [
      { name: 'Quiz 1', score: 50 },
      { name: 'Quiz 2', score: 40 },
    ],
    pendingTests: [],
    goodNotions: ['Trigonometry'],
    poorNotions: ['Algebra', 'Calculus'],
  },
  // Add more students here...
];

const Classes = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  const handleBackToClasses = () => {
    setSelectedStudent(null);
  };

  const filteredStudents = studentsData.filter(student =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getProgressBarColor = (score) => {
    if (score > 60) return 'var(--color-success)';
    if (score > 30) return 'var(--color-warning)';
    return 'var(--color-danger)';
  };

  const groupedStudents = filteredStudents.reduce((acc, student) => {
    if (!acc[student.level]) {
      acc[student.level] = {};
    }
    if (!acc[student.level][student.className]) {
      acc[student.level][student.className] = [];
    }
    acc[student.level][student.className].push(student);
    return acc;
  }, {});

  return (
    <div className="p-8">
      {selectedStudent ? (
        <StudentDetailsPage student={selectedStudent} onBack={handleBackToClasses} />
      ) : (
        <>
          <h1>Mes élèves</h1>
          <input
            type="text"
            placeholder="Rechercher un élève"
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full p-2 mb-4 border border-gray-300 rounded"
          />
          {Object.keys(groupedStudents).map(level => (
            <div key={level} className="mb-8">
              <h2>{level}</h2>
              {Object.keys(groupedStudents[level]).map(className => (
                <div key={className} className="mb-6">
                  <h3>{className}</h3>
                  {groupedStudents[level][className].map(student => (
                    <div
                      key={student.name}
                      className="student-card"
                      onClick={() => handleStudentClick(student)}
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="big-text">{student.name}</span>
                        <span>{student.className}</span>
                      </div>

                      {/* Utilisation du composant ProgressBar au lieu d'une div statique pour la barre de progression */}
                      <ProgressBar 
                        label="Réussite"  // Ajout du label "Score moyen"
                        percentage={student.averageScore}  // Le pourcentage est basé sur le score moyen de l'étudiant
                        color={getProgressBarColor(student.averageScore)}  // La couleur est définie par la fonction getProgressBarColor
                      />
                    
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Classes;