import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_URL } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { Mail, UserRound, Lock } from 'lucide-react';



interface SignupProps {
  onLogin: (userType: string) => void;
}

export default function Signup({ onLogin }: SignupProps) {
  const [searchParams] = useSearchParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const classId = searchParams.get('class_id');
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas");
      return;
    }
    
    try {
      const response = await axios.post(`${BACKEND_URL}/backapp/student/signup/`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        class_id: classId,
        password: password
      });

      if (response.status === 201) {
        onLogin('student');
        localStorage.setItem('userId', response.data.userId);
        localStorage.setItem('userFirstName', firstName);
        localStorage.setItem('userLastName', lastName);
        localStorage.setItem('email', email);
        localStorage.setItem('userType', 'student');
        navigate('/profile');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || 
                            error.response?.data?.error || 
                            '';
        const errorDetail = error.response?.data?.detail || '';
        
        toast.error(`Erreur lors de la création du compte: ${errorMessage}${errorDetail ? `: ${errorDetail}` : ''}`);
        console.error('Signup error:', error.response?.data);
      } else {
        toast.error('Erreur lors de la création du compte');
        console.error('Unexpected error:', error);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white mx-auto px-4 py-8">
      <div className="bg-white p-8 sm:p-8 rounded-lg shadow-md w-full max-w-md mx-auto w-[90vw] sm:w-[400px] md:w-[450px] lg:w-[500px] max-w-full">
        <ToastContainer />
        <div className='flex items-center justify-center'>
          <h1 className="mb-6" style={{ color: "var(--color-primary)" }}>
            edjoy!
          </h1>
        </div>
        <div className='mb-6'>
          <p className='big-text'>
            C'est parti !
          </p>
          <p className='small-text'>
            Crée ton compte élève et rejoins ta classe !
          </p>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-3">
          <div>
            <label className="subtext flex gap-1 items-center pb-0.5"><UserRound size={14} /> Prénom</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm sm:text-base"
              required
              placeholder="Entre ton prénom..."
            />
          </div>

          <div>
            <label className="subtext flex gap-1 items-center pb-0.5"><UserRound size={14} /> Nom</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm sm:text-base"
              placeholder="Entre ton nom..."
              required
            />
          </div>

          <div>
            <label className="subtext flex gap-1 items-center pb-0.5"><Mail size={14} /> Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm sm:text-base"
              required
              placeholder="Entre ton email..."
            />
          </div>

          <div>
            <label className="subtext flex gap-1 items-center pb-0.5"><Lock size={14} /> Mot de passe</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm sm:text-base"
              required
              minLength={8}
              placeholder="Entre ton mot de passe..."
            />
          </div>

          <div>
            <label className="subtext flex gap-1 items-center pb-0.5"><Lock size={14} /> Confirmer le mot de passe</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm sm:text-base"
              required
              minLength={8}
              placeholder="Confirme ton mot de passe..."
            />
          </div>

          <div className='flex items-center pt-6'>
            <button
              type="submit"
              className="button-primary mx-auto"
            >
              Valider
            </button>
          </div>

        </form>
      </div>
    </div>
  );
}