import { useParams } from 'react-router-dom';
import { Check, X } from 'lucide-react'
import { Card } from '../components/ui/card';
import ProgressBar from ".././components/ui/progressbar"


interface StudentResult {
  id: string;
  name: string;
  successRate: number;
  timeSpent: string;
  score: number;
  maxScore: number;
  answers: {
    questionId: number;
    selectedAnswers: string[];  // Array of selected answer keys (e.g. ["A", "B"])
    isCorrect: boolean;
    correctAnswers: string[];  // Array of correct answer keys
  }[];
}

interface EvaluationResult {
  id: string;
  title: string;
  date: string;
  theme: string;
  chapter: string;
  notion: string;
  difficulty: string;
  duration: string;
  questions: {
    id: number;
    question: string;
    answers: {
      text: string;
      isCorrect: boolean;
    }[];
  }[];
}

interface StudentResultViewProps {
  student: StudentResult;
  evaluation: EvaluationResult;
}

const getProgressBarColor = (score: number): string => {
  if (score > 60) return 'var(--color-success)';
  if (score > 30) return 'var(--color-warning)';
  return 'var(--color-danger)';
};

export function StudentResultView({ student, evaluation }: StudentResultViewProps) {
  return (
    <div className="space-y-4">
      <Card className="p-6 bg-[#e7f2ff]">
        <div>
          <div className="flex justify-between">
            <h2>{student.name}</h2>
            <p>{student.successRate/5}/20</p>
          </div>
          <div className="flex items-center gap-4">
          <ProgressBar
                    label="Réussite"
                    percentage={student.successRate || 0} 
                    color={getProgressBarColor(student.successRate || 0)}
                  />
          </div>
        </div>
      </Card>

      <div className="space-y-6">
      {evaluation.questions.map((question, index) => {
        const studentAnswer = student.answers.find(a => a.questionId === question.id);
        return (
          <Card key={question.id} className="p-4">
            <div className="space-y-4">
              <div>
                <h3>
                  Question {index + 1}
                </h3>
                <p className="text">{question.question}</p>
              </div>

              <div className="space-y-2">
                {question.answers.map((answer, ansIndex) => {
                  const key = String.fromCharCode(65 + ansIndex); // Convert index to A, B, C, D
                  const isSelected = studentAnswer?.selectedAnswers.includes(key);
                  const isCorrect = answer.isCorrect;
                  
                  return (
                    <div key={key} className="flex items-center gap-2">
                      <div className={`answer-number ${
                        key === 'A' ? "answer-number-1" :
                        key === 'B' ? "answer-number-2" :
                        key === 'C' ? "answer-number-3" :
                                    "answer-number-4"
                      }`}>
                        {key}
                      </div>
                      <div className={`flex-1 p-2 rounded-md ${
                        isSelected
                          ? isCorrect
                            ? "bg-[#d4edd6]"
                            : "bg-[#f9d1cf]"
                          : isCorrect
                            ? "answer-neutral"
                            : "answer-default"
                      }`}>
                        <div className="flex justify-between items-center">
                          <span>{answer.text}</span>
                          {isSelected && (
                            isCorrect ? (
                              <Check className="h-5 w-5 text-[#73c57b]" />
                            ) : (
                              <X className="h-5 w-5 text-[#ec6963]" />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>
        );
      })}
      </div>
    </div>
  );
}

