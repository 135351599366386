import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../config'; // Import BACKEND_URL

const TakeQuiz = ({ studentId, quizId, onBackClick, refreshEvaluations }) => {
  const [quizData, setQuizData] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/student/take_quiz/${studentId}/${quizId}/`);
        setQuizData(response.data);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      }
    };

    fetchQuizData();
  }, [studentId, quizId]);

  const handleAnswerChange = (questionIndex, answerIndex) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const currentAnswers = prevSelectedAnswers[questionIndex] || [];
      const newAnswers = currentAnswers.includes(answerIndex)
        ? currentAnswers.filter((index) => index !== answerIndex)
        : [...currentAnswers, answerIndex];
      return {
        ...prevSelectedAnswers,
        [questionIndex]: newAnswers,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${BACKEND_URL}/backapp/student/submit_quiz/${studentId}/${quizId}/`, {
        student_id: studentId,
        quiz_id: quizId,
        answers: selectedAnswers,
      });
      console.log('Response:', response.data);
      refreshEvaluations(); // Refresh evaluations data
      onBackClick(); // Navigate back to StudentEvaluations page
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  };

  if (!quizData) {
    return <div>Loading...</div>;
  }

  const { quizz, questions } = quizData;

  return (
    <div>
      <button onClick={onBackClick} className="mb-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
        Retour
      </button>
      {quizz && (
        <h2 className="text-xl font-semibold mb-4">{quizz.name}</h2>
      )}
      {questions.map((question, qIndex) => (
        <div key={qIndex} className="mb-4 p-4 border border-gray-300 rounded">
          <p className="font-semibold mb-2">{question.question}</p>
          {Object.entries(question.answers).map(([key, answer], aIndex) => (
            <div key={aIndex} className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={selectedAnswers[qIndex]?.includes(aIndex) || false}
                onChange={() => handleAnswerChange(qIndex, aIndex)}
                className="mr-2"
              />
              <p className="w-full p-2 border border-gray-300 rounded mr-2">
                {answer.answer}
              </p>
            </div>
          ))}
        </div>
      ))}
      <button onClick={handleSubmit} className="mt-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
        Soumettre
      </button>
    </div>
  );
};

export default TakeQuiz;