import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from './Teacher/Evaluation/components/ui/dialog';
import { Button } from './Teacher/Evaluation/components/ui/button';

const LogoutModal = ({ show, onConfirm, onCancel }) => {
  return (
    <Dialog open={show} onOpenChange={onCancel}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            Déconnexion
          </DialogTitle>
        </DialogHeader>
        <div>
          <p className="text">
            Souhaites-tu vraiment te déconnecter ?
          </p>
        </div>
        <DialogFooter className="sm:justify-end mt-4">
          <Button
            type="button"
            onClick={onConfirm}
            className="button-primary"
          >
            Se déconnecter
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LogoutModal;