import { useEffect, useState } from "react"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"
import { Pencil, Sparkles, Trash2, ChevronLeft, Edit2 } from 'lucide-react'
import { Button } from "../components/ui/button"
import { Card } from "../components/ui/card"
import { Input } from "../components/ui/input"
import axios from 'axios'
import { BACKEND_URL } from '../../../../config'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import { toast, ToastContainer } from 'react-toastify'
import { Theme } from '../../../../types/theme'
import { Chapter } from '../../../../types/chapter'
import { Notion } from '../../../../types/notion'
import { SmoothSelector } from "../../../../components/multiselect/multiselect"
import { AnimatePresence, motion } from "framer-motion";


interface Answer {
  answer: string
  isTrue: boolean
}

interface Question {
  id: number
  question: string
  answers: {
    A: Answer
    B: Answer
    C: Answer
    D: Answer
  }
  correct_answers: string[]
  notions: string[]
  themes: string[]
  chapter: string[]
  subject: number | null
}

interface Quiz {
  quiz_id: number
  name: string
  questions: Question[]
  themes: string[]  
  chapters: string[]
  notions: string[]
}

export function GeneratePage() {
  const [searchParams] = useSearchParams()
  const quizz_id = searchParams.get('id')
  const navigate = useNavigate()
  const [quiz, setQuiz] = useState<Quiz | null>(null)
  const [themes, setThemes] = useState<Theme[]>([])
  const [chaptersMap, setChaptersMap] = useState<Record<number, Chapter[]>>({})
  const [notionsMap, setNotionsMap] = useState<Record<number, Notion[]>>({})
  const isNewQuiz = searchParams.get('new') === 'true'
  const isAiGenerated = searchParams.get('aiGenerated') === 'true';
  const [isEditingName, setIsEditingName] = useState(false);
  const [quizName, setQuizName] = useState("");
  const [editingNotionsForQuestion, setEditingNotionsForQuestion] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotions, setSelectedNotions] = useState<string[]>(quiz?.notions || []);

  useEffect(() => {
    if (quiz?.name) {
      setQuizName(quiz.name);
    }
  }, [quiz?.name]);

  useEffect(() => {
    console.log("Current quiz.notions:", quiz?.notions);
  }, [quiz?.notions]);

  const handleNameChange = (newName: string) => {
    setQuizName(newName);
    setQuiz(prev => prev ? {
      ...prev,
      name: newName
    } : null);
  };

  useEffect(() => {
    if (isAiGenerated) {
      toast.success("Questions générées avec succès par l'IA", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [isAiGenerated]);

  useEffect(() => {
    if (isNewQuiz) {
      toast.success("Évaluation créée avec succès", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [isNewQuiz]);

  useEffect(() => {
    if (quizz_id) {
      fetchQuizData();
    } else {
      // Initialize empty quiz if no quizz_id
      setQuiz({
        quiz_id: 0,
        name: "",
        questions: [],
        themes: [],
        chapters: [],
        notions: []
      });
    }

  }, [quizz_id]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editingNotionsForQuestion !== null) {
        const target = event.target as HTMLElement;
        if (!target.closest('.notion-selector-container')) {
          setEditingNotionsForQuestion(null);
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [editingNotionsForQuestion]);


  useEffect(() => {
    fetchThemes()
  }, [])

  useEffect(() => {
    if (quiz?.questions) {
      quiz.questions.forEach(question => {
        if (question.themes[0]) {
          fetchChapters(question.themes[0].toString(), question.id);
        }
        if (question.chapter[0]) {
          fetchNotions(question.chapter[0].toString(), question.id);
        }
      });
    }
  }, [quiz]);

  const fetchThemes = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          quizz_id: quizz_id
        },
      })
      setThemes(response.data.themes)
    } catch (error) {
      console.error('Error fetching themes:', error)
    }
  }

  const fetchChapters = async (themeId: string, questionId: number) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/themes/chapters`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          theme_id: themeId,
        },
      })
      setChaptersMap(prev => ({
        ...prev,
        [questionId]: response.data.chapters
      }))
    } catch (error) {
      console.error('Error fetching chapters:', error)
    }
  }

  const fetchNotions = async (chapterId: string, questionId: number) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/chapters/notions`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          chapter_id: chapterId,
        },
      })
      setNotionsMap(prev => ({
        ...prev,
        [questionId]: response.data.notions
      }))
    } catch (error) {
      console.error('Error fetching notions:', error)
    }
  }

  const handleThemeChange = (questionId: number, themeId: string) => {
    handleUpdateQuestion(questionId, {
      themes: [themeId], // Keep as string
      chapter: [], 
      notions: [], 
    });
    fetchChapters(themeId, questionId);
  };
  
  const handleChapterChange = (questionId: number, chapterId: string) => {
    handleUpdateQuestion(questionId, {
      chapter: [chapterId], // Keep as string
      notions: [], 
    });
    fetchNotions(chapterId, questionId);
  };
  
  const handleNotionChange = (questionId: number, notionNames: string[]) => {
    handleUpdateQuestion(questionId, {
      notions: notionNames
    });
  };

  const fetchAllData = async () => {
    try {
      // Fetch themes using your API endpoint
      const themesResponse = await axios.get(`${BACKEND_URL}/backapp/teacher/themes`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          quizz_id: quizz_id
        },
      });
    
      // For each theme, fetch chapters and for each chapter, fetch notions
      const themesWithChapters = await Promise.all(
        themesResponse.data.themes.map(async (theme: any) => {
          const chaptersResponse = await axios.get(`${BACKEND_URL}/backapp/teacher/themes/chapters`, {
            params: {
              teacher_id: localStorage.getItem('userId'),
              theme_id: theme.id,
            },
          });
    
          const chapters = await Promise.all(
            chaptersResponse.data.chapters.map(async (chapter: any) => {
              const notionsResponse = await axios.get(`${BACKEND_URL}/backapp/teacher/chapters/notions`, {
                params: {
                  teacher_id: localStorage.getItem('userId'),
                  chapter_id: chapter.id,
                },
              });
    
              return {
                id: chapter.id.toString(),
                name: chapter.name,
                notions: notionsResponse.data.notions.map((notion: any) => ({
                  id: notion.id.toString(),
                  name: notion.name,
                })) || []
              };
            })
          );
    
          return {
            id: theme.id.toString(),
            name: theme.name,
            chapters: chapters || []
          };
        })
      );
    
      // Set the themes state for the SmoothSelector
      setThemes(themesWithChapters);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error("Erreur lors du chargement des données");
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [quizz_id]);
  



  const fetchQuizData = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/backapp/teacher/retrieve_quiz_in_preperation`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          quiz_id: quizz_id
        }
      })
      console.log('Quiz fetched:', response.data)
      setQuiz(response.data)
    } catch (error) {
      console.error('Error fetching quiz:', error)
    }
  }

  useEffect(() => {
    if (quiz) {
      setSelectedNotions(quiz.notions);
    }
  }, [quiz]);

  const handleGenerateWithAI = () => {
    if (!quiz) return;
    navigate(`/evaluation/ai/${quiz.quiz_id}`, {
      state: {
        quiz_id: quiz.quiz_id,
        name: quiz.name,
        notions: quiz.notions,
        themes: quiz.themes,
        chapters: quiz.chapters,
      }
    });
  };

  const handleCreateManually = () => {
    setQuiz(prev => {
      if (!prev) return prev;
      const tempId = prev.questions.length > 0 
      ? Math.max(...prev.questions.map(q => q.id)) + 1 
      : 1;

      return {
        ...prev,
        questions: [
          ...(prev.questions || []),
          {
            id: tempId,
            newQuestion: true,
            question: "",
            answers: {
              A: { answer: "", isTrue: false },
              B: { answer: "", isTrue: false },
              C: { answer: "", isTrue: false },
              D: { answer: "", isTrue: false }
            },
            correct_answers: [],
            notions: [],
            themes: [],
            chapter: [],
            subject: null 
          }
        ]
      };
    });
  };

  const handleUpdateQuestion = (questionId: number, updates: Partial<Question>) => {
    setQuiz((prev: Quiz | null) => {
      if (!prev) return prev;
      return {
        ...prev,
        questions: prev.questions.map((q: Question) =>
          q.id === questionId ? { ...q, ...updates } : q
        ),
      };
    });
  };
  
  const handleDeleteQuestion = (questionId: number | null) => {
    setQuiz((prev: Quiz | null) => {
      if (!prev) return prev;
      return {
        ...prev,
        questions: prev.questions.filter((q) => q.id !== questionId),
      };
    });
  };
  
  const handleSave = async () => {
    try {
      console.log('Saving quiz:', quiz);
      if (!quiz) return;
      const response = await axios.post(
        `${BACKEND_URL}/backapp/teacher/save_quiz_in_preperation`,
        {
          ...quiz,
          teacher_id: localStorage.getItem('userId'),
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        console.log('Quiz saved successfully');
        navigate('/evaluations', { state: { showSaveSuccess: true } });
      } else {
        toast.error("Erreur lors de l'enregistrement de l'évaluation", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.error('Error saving quiz:', response.statusText);
      }
    } catch (error) {
      toast.error("Erreur lors de l'enregistrement de l'évaluation", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error('Error saving quiz:', error);
    }
  };
  useEffect(() => {
    if (quiz?.questions) {
      quiz.questions.forEach((question) => {
        console.log(`Question ${question.id} notions:`, question.notions);
      });
    }
  }, [quiz?.questions]);

  return (
    <div className="container mx-auto py-8 px-8">
      <button className="button-clear" onClick={() => navigate(-1)}>
       <ChevronLeft size={16} />
        Retour
      </button>
      <ToastContainer />
      <Card className="background-primary">
        <div>
          <div className="flex flex-wrap justify-between items-start">
            <div>
              {isEditingName ? (
                <Input
                  value={quizName}
                  onChange={(e) => handleNameChange(e.target.value)}
                  onBlur={() => setIsEditingName(false)}
                  className="text mb-0" // Use your standard name class
                  autoFocus
                />
              ) : (
                <div className="flex items-center gap-2">
                  <h1 className="mb-0"> {/* Use your standard name class */}
                    {quiz?.name || "Nouvelle évaluation"}
                  </h1>
                  <button
                    onClick={() => setIsEditingName(true)}
                    className="button-icon"
                    title="Modifier le nom"
                  >
                    <Edit2 size={12} />
                  </button>
                </div>
              )}
              <p className="subtext">
                {new Date().toLocaleDateString()}
              </p>
            </div>          
            {(quiz?.questions?.length || 0) > 0 && (
              <p className="big-text my-4">{quiz?.questions?.length} questions</p>
            )}
          </div>
          <div className="flex flex-wrap gap-2 mt-4">
            <div>
              <p className="subtext">Thèmes</p>
              <div className="flex flex-wrap gap-2">
                {quiz?.themes?.map((theme, index) => (
                  <span key={index} className="notion-label">
                    {theme}
                  </span>
                ))}
                {(!quiz?.themes || quiz.themes.length === 0) && (
                  <span className="notion-label">Non défini</span>
                )}
              </div>
            </div>
            <div>
              <p className="subtext">Chapitres</p>
              <div className="flex flex-wrap gap-2">
                {quiz?.chapters?.map((chapter, index) => (
                  <span key={index} className="notion-label">
                    {chapter}
                  </span>
                ))}
                {(!quiz?.chapters || quiz.chapters.length === 0) && (
                  <span className="notion-label">Non défini</span>
                )}
              </div>
            </div>
            <div className="flex flex-wrap gap-2 mt-4">
              <div>
                <p className="subtext">Notions</p>
                <div className="flex items-center justify-between">
                  <div className="flex flex-wrap gap-2">
                    {selectedNotions.length > 0 ? (
                      selectedNotions.map((notionId, index) => {
                        // Look up the notion name from your themes hierarchy.
                        let notionName = notionId;
                        themes.forEach(theme => {
                          theme.chapters?.forEach(chapter => {
                            const found = chapter.notions.find(n => n.id === notionId);
                            if (found) {
                              notionName = found.name;
                            }
                          });
                        });
                        return (
                          <span key={index} className="notion-label">
                            {notionName}
                          </span>
                        );
                      })
                    ) : (
                      <span className="notion-label">Non défini</span>
                    )}
                  </div>
                  <Button
                    onClick={() => setIsModalOpen(true)}
                    className="button-icon"
                    title="Modifier les notions"
                  >
                    <Edit2 size={16} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsModalOpen(false)}
          >
            <motion.div
              className="bg-white p-6 rounded-lg max-w-3xl w-full max-h-[80vh] overflow-y-auto"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
            >
              <SmoothSelector
                themes={themes}
                selectedNotions={selectedNotions}
                onNotionSelect={(newNotions: string[]) => {
                  // Update parent state without closing the modal immediately,
                  // allowing you to select or deselect multiple notions.
                  setSelectedNotions(newNotions);
                }}
              />
              <Button
                onClick={() => {
                  // Convert selectedNotions (IDs) into names from the themes hierarchy.
                  const newNotions = selectedNotions.map((notionId) => {
                    let notionName = notionId;
                    themes.forEach((theme) => {
                      theme.chapters?.forEach((chapter) => {
                        const found = chapter.notions.find(n => n.id === notionId);
                        if (found) {
                          notionName = found.name;
                        }
                      });
                    });
                    return notionName;
                  });
                  // Now update the quiz with the new notion names.
                  setQuiz((prev) => prev ? { ...prev, notions: newNotions } : prev);
                  setIsModalOpen(false);
                }}
                className="button-primary"
              >
                Sauvegarder
              </Button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
  
      <div className="background-dashed mb-8 m-4">
        <h3>Ajout de questions</h3>
          <p className="small-text mb-4">
            {quizz_id ? "" : "Évaluation créée ! À toi de la remplir avec tes questions !"}
          </p>
        <div className="flex flex-wrap gap-4">
          <Button
            onClick={handleGenerateWithAI}
            className="button-primary"
          >
            <Sparkles size={16} />
            Générer avec l'IA
          </Button>
          <Button
            variant="outline"
            onClick={handleCreateManually}
            className="button-outline"
          >
            <Pencil size={16} />
            Créer soi-même
          </Button>
        </div>
      </div>
  
      <div className="space-y-6">
        {quiz?.questions?.map((question, index) => (
          <Card key={question.id} className="p-6">
            <div className="flex justify-between items-start">
              <h3> Question {index + 1} </h3>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleDeleteQuestion(question.id)}
                  className="button-icon-danger"
                >
                  <Trash2 size={16} />
                </Button>
              </div>
            </div>
  
            <div className="space-y-8">

              {/* Theme/Chapter/Notion selectors */}
              <div className="space-y-4">
                <div className="notion-selector-container">
                  <div className="flex items-center justify-between mb-2">
                    <p className="subtext">Notions évaluées dans cette question</p>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingNotionsForQuestion(
                          editingNotionsForQuestion === question.id ? null : question.id
                        );
                      }}
                      className="button-icon"
                      title="Modifier les notions"
                    >
                      <Edit2 size={14} />
                    </button>
                  </div>
                  
                  {editingNotionsForQuestion === question.id ? (
                    <div className="flex flex-wrap gap-2 mt-2">
                      {selectedNotions.length > 0 ? (
                        selectedNotions.map((notionId) => {
                          // Look up the notion name from themes
                          let notionName = notionId;
                          themes.forEach(theme => {
                            theme.chapters?.forEach(chapter => {
                              const found = chapter.notions.find(n => n.id === notionId);
                              if (found) {
                                notionName = found.name;
                              }
                            });
                          });
                          return (
                            <button
                              key={notionId}
                              onClick={() => {
                                const currentNotions = question.notions || [];
                                const newNotions = currentNotions.includes(notionId)
                                  ? currentNotions.filter(n => n !== notionId)
                                  : [...currentNotions, notionId];
                                handleNotionChange(question.id, newNotions);
                              }}
                              className={`px-3 py-1.5 rounded-full text-sm transition-colors ${
                                question.notions?.includes(notionId)
                                  ? "bg-[#1550a7] text-white"
                                  : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                              }`}
                            >
                              {notionName}
                            </button>
                          );
                        })
                      ) : (
                        <span className="text-gray-500 text-sm">
                          Aucune notion disponible pour cette évaluation
                        </span>
                      )}
                    </div>
                  ) : (
                    // Collapsed view with only selected notions
                    <div className="flex flex-wrap gap-2 mt-2">
                      {question.notions?.map((notionId) => {
                        let notionName = notionId;
                        themes.forEach((theme) => {
                          theme.chapters?.forEach((chapter) => {
                            const found = chapter.notions.find(n => n.id === notionId.toString());
                            if (found) {
                              notionName = found.name;
                            }
                          });
                        });
                        return (
                          <span
                            key={notionId}
                            className="px-3 py-1.5 rounded-full text-sm bg-[#1550a7] text-white"
                          >
                            {notionName}
                          </span>
                        );
                      })}
                      {(!question.notions || question.notions.length === 0) && (
                        <span className="text-gray-500 text-sm">
                          Aucune notion sélectionnée
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>

              
              <div>
                <p className="subtext">Intitulé de la question</p>
                <Input
                  value={question.question}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { question: e.target.value })
                  }
                  placeholder="Intitulé de la question"
                  className="font-medium"
                />
              </div>

              <div className="space-y-4">
                {Object.entries(question.answers).map(([key, answer]) => (
                  <div key={key} className="flex items-center gap-2">
                    <div className={`answer-number ${
                      key === 'A' ? "answer-number-1" :
                      key === 'B' ? "answer-number-2" :
                      key === 'C' ? "answer-number-3" :
                      "answer-number-4"
                    }`}>
                      {key}
                    </div>
                    <div className={`flex-1 ${answer.isTrue ? "bg-[#d4edd6]" : "bg-[#f3f9ff]"} rounded-md`}>
                      <Input
                        value={answer.answer}
                        onChange={(e) =>
                          handleUpdateQuestion(question.id, {
                            answers: {
                              ...question.answers,
                              [key]: { ...answer, answer: e.target.value }
                            }
                          })
                        }
                        placeholder={`Réponse ${key}`}
                        className="border-0 bg-transparent"
                        />
                    </div>
                    <input
                      type="checkbox"
                      checked={answer.isTrue}
                      onChange={(e) =>
                        handleUpdateQuestion(question.id, {
                          answers: {
                            ...question.answers,
                            [key]: { ...answer, isTrue: e.target.checked }
                          }
                        })
                      }
                      className="w-5 h-5 rounded border-gray-300"
                      />
                  </div>
                ))}
              </div>
            </div>
          </Card>
        ))}
      </div>
  
      {(quiz?.questions?.length || 0) > 0 && (
        <div className="mt-8 flex justify-end centered">
          <Button
            onClick={handleSave}
            className="button-primary"
          >
            Enregistrer
          </Button>
        </div>
      )}
    </div>
  );
}