import React from 'react';
import ProgressBar from '../../ProgressBar';


const ClassProgress = ({ className, avancement, reussite }) => {
  const getReussiteColor = (percentage) => {
    if (percentage < 25) return 'var(--color-danger)';
    if (percentage < 60) return 'var(--color-warning)';
    return 'var(--color-success)';
  };

  return (
    <div class='background-secondary'>
      <p className="big-text">{className}</p>
      <div className="flex space-x-4">
        {/*<ProgressBar label="Réussite" percentage={reussite} color={getReussiteColor(reussite)} />*/}
      </div>
    </div>
  );
};

export default ClassProgress;