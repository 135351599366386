import { useState, useEffect } from "react"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import {ChevronLeft} from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import { SmoothSelector } from '../../../multiselect/multiselect'
import type { EvaluationFormData } from "../types/evaluation"
import { BACKEND_URL } from '../../../../config'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { Theme } from '../../../../types/theme';
import { Chapter } from '../../../../types/chapter';
import { Notion } from '../../../../types/notion';



export function EvaluationSetup() {
  const [searchParams] = useSearchParams()
  const selectedYear = searchParams.get('year_id')
  const navigate = useNavigate();
  const [themes, setThemes] = useState<Theme[]>([]);
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [notions, setNotions] = useState<Notion[]>([]);
  const [formData, setFormData] = useState<EvaluationFormData>({
    title: "",
    theme: "",
    chapter: "",
    notion: "",
  })
  const [selectedNotions, setSelectedNotions] = useState<string[]>([])

  const fetchAllData = async () => {
    try {
      // First fetch themes
      const themesResponse = await axios.get(`${BACKEND_URL}/backapp/teacher/themes`, {
        params: {
          teacher_id: localStorage.getItem('userId'),
          year_id: selectedYear,
        },
      });

      // For each theme, fetch its chapters
      const themesWithChapters = await Promise.all(themesResponse.data.themes.map(async (theme: any) => {
        const chaptersResponse = await axios.get(`${BACKEND_URL}/backapp/teacher/themes/chapters`, {
          params: {
            teacher_id: localStorage.getItem('userId'),
            theme_id: theme.id,
          },
        });

        // For each chapter, fetch its notions
        const chapters = await Promise.all(chaptersResponse.data.chapters.map(async (chapter: any) => {
          const notionsResponse = await axios.get(`${BACKEND_URL}/backapp/teacher/chapters/notions`, {
            params: {
              teacher_id: localStorage.getItem('userId'),
              chapter_id: chapter.id,
            },
          });

          return {
            id: chapter.id.toString(),
            name: chapter.name,
            notions: notionsResponse.data.notions.map((notion: any) => ({
              id: notion.id.toString(),
              name: notion.name
            }))
          };
        }));

        return {
          id: theme.id.toString(),
          name: theme.name,
          chapters: chapters
        };
      }));

      setThemes(themesWithChapters);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error("Erreur lors du chargement des données");
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [selectedYear]);

  const transformThemeData = (apiThemes: any[]): Theme[] => {
    return apiThemes.map(theme => ({
      id: theme.id.toString(), // Convert to string
      name: theme.name,
      chapters: theme.chapters?.map((chapter: any) => ({
        id: chapter.id.toString(),
        name: chapter.name,
        notions: chapter.notions?.map((notion: any) => ({
          id: notion.id.toString(),
          name: notion.name
        })) || []
      })) || []
    }));
  };

  const getSelectedThemesAndChapters = (selectedNotionIds: string[], themes: Theme[]) => {
    const selectedThemes = new Set<string>();
    const selectedChapters = new Set<string>();
  
    themes.forEach(theme => {
      theme.chapters.forEach(chapter => {
        chapter.notions.forEach(notion => {
          if (selectedNotionIds.includes(notion.id)) {
            selectedThemes.add(theme.id);
            selectedChapters.add(chapter.id);
          }
        });
      });
    });
  
    return {
      themeIds: Array.from(selectedThemes),
      chapterIds: Array.from(selectedChapters),
    };
  };

  const isFormValid = () => {
    return formData.title.trim() !== "" && selectedNotions.length > 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    // Get all selected themes and chapters based on selected notions
    const { themeIds, chapterIds } = getSelectedThemesAndChapters(selectedNotions, themes);
  
    const evaluationData = {
      teacher_id: localStorage.getItem('userId'),
      name: formData.title,
      subject_id: localStorage.getItem('subjectId'),
      theme_ids: themeIds.map(id => parseInt(id)),
      chapter_ids: chapterIds.map(id => parseInt(id)),
      notion_ids: selectedNotions.map(id => parseInt(id)),
      year: selectedYear
    };
  
    console.log('Sending evaluation data:', evaluationData);
  
    try {
      const response = await axios.post(
        `${BACKEND_URL}/backapp/teacher/create_evaluation`,
        evaluationData
      );
  
      if (response.status === 201) {
        const quizId = response.data.quiz_id;
        navigate(`/evaluation/generate?id=${quizId}&new=true`);
      }
    } catch (error) {
      console.error('Error creating evaluation:', error);
      toast.error("Erreur lors de la création de l'évaluation", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleNotionSelect = (notionIds: string[]) => {
    setSelectedNotions(notionIds)
    if (notionIds.length > 0) {
      // Get the first selected notion for the form
      const notion = notions.find(n => n.id.toString() === notionIds[0])
      if (notion) {
        setFormData(prev => ({
          ...prev,
          notion: notion.id.toString()
        }))
      }
    }
  }

  return (
    <div className="p-8">
      <button className="button-clear"
      onClick={() => navigate(-1)}>
        <ChevronLeft size={16} />
        Retour
      </button>

    <div className="background-secondary container max-w-2xl mx-auto mt-8">
      <ToastContainer />
      <h2>
        Titre de l'évaluation
      </h2>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div>
          <p className="small-text">Comment veux-tu appeler ton évaluation ?</p>
          <label className="subtext">
            Titre de l'évaluation
          </label>
          <Input
            placeholder="Bilan de fin de chapitre"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            required
            />
        </div>

        <div>
            <div>
              <h2>Notions évaluées</h2>
              <p className="small-text">
                Quelle(s) notion(s) souhaites-tu évaluer dans cette évaluation ?
              </p>
              <SmoothSelector
                themes={transformThemeData(themes)}
                onNotionSelect={handleNotionSelect}
                selectedNotions={selectedNotions}
              />
            </div>
          </div>
        
          <div className="centered">
            <Button
              type="submit"
              className="button-primary"
              disabled={!isFormValid()}
              >
              Enregistrer
            </Button>
          </div>
      </form>
    </div>
    </div>
  )
}

