import { useState, useEffect } from "react"
import { useParams, useNavigate } from 'react-router-dom';
import { Folder, Users, User, ChevronLeft, Check, X} from 'lucide-react'
import { Button } from "../../components/ui/button"
import { Card } from "../../components/ui/card"
import type { EvaluationResult } from "../../types/results"
import { StudentSelector } from "../../components/student-selector"
import { StudentResultView } from "../../components/student-result-view"
import type { StudentResult } from "../../types/student-results"
import axios from 'axios'
import { BACKEND_URL } from '../../../../../config'
import ProgressBar from "../../components/ui/progressbar"



interface Answer {
  answer: string;
  isTrue: boolean;
}

interface QuestionStats {
  question_id: number;
  question_text: string;
  answers: Record<string, Answer>;
  answer_stats: Record<string, number>;
  correct_percentage: number;
  attempt_count: number;
  total_students: number;
}

interface QuizInfo {
  quiz_id: string;
  name: string;
  theme: string;
  chapter: string;
  notion: string;
  shared_date: string;
  average_score: number;
  total_students: number;
  attempted_count: number;
  attempt_rate: number;
}

interface StudentAnswer {
  selected_answers: string[];
  is_correct: boolean;
}

interface StudentStats {
  name: string;
  class: {
    id: number;
    name: string;
  };
  attempted: boolean;
  score: number | null;
  answers: Record<string, {
    selected_answers: StudentAnswer | [];
    is_correct: boolean;
    correct_answers: string[];
  }>;
}

interface QuizResults {
  quiz_info: QuizInfo;
  questions_stats: QuestionStats[];
  student_stats: StudentStats[];
}

export default function ResultsPage() {
  const [results, setResults] = useState<QuizResults | null>(null);
  const [view, setView] = useState<"general" | "student" | "overview">("overview");
  const [selectedStudentId, setSelectedStudentId] = useState<string>("");
  const { id } = useParams();
  const navigate = useNavigate()


  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/backapp/teacher/quiz-results`, {
          params: {
            quizz_id: id
          }
        });
        setResults(response.data);
        // Set first attempted student as selected by default
        const firstAttemptedStudent = response.data.student_stats.find(
          (student: StudentStats) => student.attempted
        );
        if (firstAttemptedStudent) {
          setSelectedStudentId(firstAttemptedStudent.name);
        }
      } catch (error) {
        console.error('Error fetching quiz results:', error);
      }
    };

    if (id) {
      fetchResults();
    }
  }, [id]);

  const getProgressBarColor = (score: number): string => {
    if (score > 60) return 'var(--color-success)';
    if (score > 30) return 'var(--color-warning)';
    return 'var(--color-danger)';
  };

  if (!results) return null;

  const selectedStudent = results.student_stats.find(s => s.name === selectedStudentId);


  const handlePreviousStudent = () => {
    const currentIndex = results.student_stats.findIndex(s => s.name === selectedStudentId);
    if (currentIndex > 0) {
      setSelectedStudentId(results.student_stats[currentIndex - 1].name);
    }
  };
  
  const handleNextStudent = () => {
    const currentIndex = results.student_stats.findIndex(s => s.name === selectedStudentId);
    if (currentIndex < results.student_stats.length - 1) {
      setSelectedStudentId(results.student_stats[currentIndex + 1].name);
    }
  };


  return (
    <div className="container mx-auto py-8 px-8">
      <button className="button-clear" onClick={() => navigate(-1)}>
       <ChevronLeft size={16}/>
        Retour
      </button>
      <Card className="background-primary">
        <div>
          <div>
            <h1 className="mb-0">{results.quiz_info.name}</h1>
            <p className="subtext">{new Date(results.quiz_info.shared_date).toLocaleDateString()}</p>
          </div>

          <div className="flex flex-wrap gap-2 mt-4">
            <div>
              <p className="subtext">Thème</p>
              <span className="notion-label">
                {results.quiz_info.theme || "Non défini"}
              </span>
            </div>
            <div>
              <p className="subtext">Chapitre</p>
              <span className="notion-label">
                {results.quiz_info.chapter || "Non défini"}
              </span>
            </div>
            <div>
              <p className="subtext">Notion</p>
              <span className="notion-label">
                {results.quiz_info.notion || "Non défini"}
              </span>
            </div>
          </div>
        </div>
      </Card>


      <div className="flex justify-center flex-wrap gap-4 mb-8">
        <button onClick={() => setView("overview")} className={`button-selector ${view === 'overview' ? 'selected' : ''}`}>
          <Users size={20} />
          Vue générale
        </button>
        <button onClick={() => setView("general")} className={`button-selector ${view === 'general' ? 'selected' : ''}`}>
          <Folder size={20} />
          Vue par question
        </button>
        {selectedStudent && (
          <button onClick={() => setView('student')} className={`button-selector ${view === 'student' ? 'selected' : ''}`}>
            <User size={20} />
            Vue par élève
          </button>
        )}
      </div>
      {view === "overview" && (
        <div className="space-y-4">
          {results.student_stats.map((student) => (
            <Card 
              key={student.name} 
              className="p-4 cursor-pointer hover:bg-gray-50"
              onClick={() => {
                setSelectedStudentId(student.name);
                setView("student");
              }}
            >
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="font-medium">{student.name}</h3>
                  <p className="text-sm text-gray-500">{student.class.name}</p>
                </div>
                <div className="flex items-center gap-4">
                  {student.attempted ? (
                    <>
                      <ProgressBar
                        label="Score"
                        percentage={student.score || 0}
                        color={getProgressBarColor(student.score || 0)}
                      />
                      <p className="font-medium">{(student.score || 0)/5}/20</p>
                    </>
                  ) : (
                    <p className="text-sm text-gray-500">Non effectuée</p>
                  )}
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
      {view === "general" && (
  <div className="space-y-4">
    {results.questions_stats.map((question: QuestionStats, index: number) => (
      <Card key={question.question_id} className="p-4">
        <div className="space-y-6 pb-4 border-b">
          <div>
            <h3> Question {index + 1} </h3>
            <p className="text">{question.question_text}</p>
          </div>

          <div className="space-y-2">

            {Object.entries(question.answers).map(([key, answer], ansIndex) => {
                const isCorrect = answer.isTrue; // Utilisation directe de answer
                return (

            
              <div key={key} className="flex items-start gap-2">
                <div
                  className={`answer-number ${
                    ansIndex === 0
                      ? "answer-number-1"
                      : ansIndex === 1
                      ? "answer-number-2"
                      : ansIndex === 2
                      ? "answer-number-3"
                      : "answer-number-4"
                  }`}
                >
                  {key}
                      </div>
                      <div className="flex-1">
                        <div className="flex justify-between">
                          <span className={`flex-1 p-3 rounded-md ${isCorrect ? "answer-success" : "answer-neutral"}`}>{answer.answer}</span>
                        </div>
                        <ProgressBar
                          label="Réponses obtenues"
                          percentage={question.answer_stats[key] || 0} //* Use answer_stats from QuestionStats instead of answer.percentage
                          color={`${isCorrect ? "var(--color-success)" : "var(--color-danger)"}`}
                        />

                      </div>
                    </div>
                    );
                  })}
                  
                </div>

                <div className="background-secondary grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                  <ProgressBar
                    label="Réussite à la question"
                    percentage={question.correct_percentage || 0} 
                    color={getProgressBarColor(question.correct_percentage || 0)}
                  />

                  <ProgressBar
                    label="Participation des élèves"
                    percentage={(question.attempt_count / results.quiz_info.total_students) * 100 || 0} 
                    color={getProgressBarColor((question.attempt_count / results.quiz_info.total_students) * 100 || 0)}
                  />
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}

      {view === "student" && selectedStudent && (
        <>
          <StudentSelector
            students={results.student_stats.map(s => ({ id: s.name, name: s.name }))}
            selectedStudentId={selectedStudentId}
            onSelectStudent={setSelectedStudentId}
            onPreviousStudent={handlePreviousStudent}
            onNextStudent={handleNextStudent}
          />
          <StudentResultView
            student={{
              id: selectedStudent.name,
              name: selectedStudent.name,
              successRate: selectedStudent.score || 0,
              timeSpent: "N/A",
              score: selectedStudent.score || 0,
              maxScore: 100,
              answers: Object.entries(selectedStudent.answers).map(([id, data]) => {
                // Get selected answers array
                const selectedAnswers = Array.isArray(data.selected_answers) 
                  ? [] // Empty array for unanswered questions
                  : data.selected_answers.selected_answers; // Array of selected answer keys (e.g. ["A", "B"])
                
                return {
                  questionId: parseInt(id),
                  selectedAnswers, // Pass the array of selected answers
                  isCorrect: data.is_correct,
                  correctAnswers: data.correct_answers
                };
              })
            }}
            evaluation={{
              id: results.quiz_info.quiz_id,
              title: results.quiz_info.name,
              date: results.quiz_info.shared_date,
              theme: results.quiz_info.theme,
              chapter: results.quiz_info.chapter,
              notion: results.quiz_info.notion,
              difficulty: "medium",
              duration: "N/A",
              questions: results.questions_stats.map(q => ({
                id: q.question_id,
                question: q.question_text,
                answers: Object.entries(q.answers).map(([key, answer]) => ({
                  key, // Include the answer key (A, B, C, D)
                  text: answer.answer,
                  isCorrect: answer.isTrue
                }))
              }))
            }}
          />
        </>
      )}
    </div>
  )
}